export const QUOTE_COMPONENT = `
 name 
 picture {
   image 
 }
 quoteText {
   quote
   citedPerson 
   backgroundColor 
   textColor 
   textPosition
 }
`;
