import styled from 'styled-components';
import { Grid, makeStyles } from '@material-ui/core';
import { theme } from '../../theme';

export const LinkGrid = styled(Grid)`
    padding: 36px 30px;
`;

export const TextBar = styled(Grid)`

    margin-bottom: 26px;

    &.no-margin {
        margin-bottom: 0;
    }
`;

export const TitleH2 = styled.h2`
    font: ${theme.fontDesktop.SombraBlack_60};
    color: ${theme.color.blau2};
    padding-bottom: 36px;
    @media (max-width: ${theme.breakPointMobile}) {
        font: ${theme.fontMobile.SombraBlack_40};
    }
`;

export const Wrapper = styled(Grid)`
    background-color: ${theme.color.beige};
`;

export const Container = styled(Grid)`
    padding: 33px 46px 31px;
    background-color: ${theme.color.beige};
    width: 100%;
    font: ${theme.fontDesktop.SombraRegular_26};
    color: ${theme.color.blau1};
    text-align: start;

    @media (max-width: ${theme.breakPointMobile}) {
        font: ${theme.fontMobile.SombraRegular_26};
    }
`;

export const Text = styled.p`
    font: ${theme.fontDesktop.SombraRegular_26};
    color: ${theme.color.white};
`;

export const Spacer = styled.div`
    margin-bottom: 70px;
`;

export const useStyles = makeStyles({
  root: {
    color: 'grey',
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiFormLabel-asterisk': {
      color: '#132644',
    },
    '& .MuiFormGroup-root': {
      justifyContent: 'center',
    },
    '& .MuiButtonBase-root': {
      alignSelf: 'flex-start',
    },
    '& .Mui-checked': {
      color: '#132644',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #808080',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#132644',
    },
    '& .MuiFormControl-root': {
      textAlign: 'left',
      margin: 0,
    },
    '& .MuiAlert-standardError': {
      backgroundColor: 'transparent !important',
      padding: '0',
      paddingLeft: '7px',
    },
    '& .SendButton': {
      marginTop: '10px',
    },
    '& .MuiTypography-root': {
      textAlign: 'left',
      margin: 0,
      paddingTop: '7px',
    },
    '& .GenderField': {
      paddingLeft: '12px',
    },
    '& .CheckboxField': {
      paddingLeft: '9px !important',
      paddingTop: '7px',
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Sombra-Regular !important',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Sombra-Regular !important',
    },
    '& .MuiRadio-colorSecondary': {},
    '& .MuiFormLabel-root': {
      fontFamily: 'Sombra-Regular !important',
    },
    '& .MuiFilledInput-multiline': {
      background: 'white',
    },
    '& .MuiFilledInput-input': {
      width: '100%',
      background: 'white',
    },
    '& .MuiGrid-root': {
      textAlign: 'left',
      '@media (max-width: 500px)': {
        padding: '5px',
      },
    },
  },
  phone: {
    '&:hover, &:focus': {
      border: '1px solid #808080',

      '& .flag-dropdown': {
        border: '1px solid black',
      },
    },
  },
  phoneContainer: {
    '&:hover, &:focus-within': {
      '& .flag-dropdown': {
        border: '1px solid #808080',
      },
    },
  },
});

export {};
