export const FAQ_COMPONENT = `
name
titleContainer{
    title
    ankerId
    description
    listIcon
    isH1
    faqClusterContainer{
        ankerId
        titleCluster
        descriptionCluster
        questionContainer{
            question
            questionContent
            listIcon
            hasButton
            buttonText
            buttonLink
            buttonBackgroundColor
            isNewTab
            isDownloadButton
            downloadFile
        }
    }
}
`;
