import React, { ChangeEventHandler } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { theme } from '../../theme';
import HandyCheckbox from '../../assets/Handy_Checkbox.webp';
import TelefonPlus from '../../assets/Telefon_Plus.webp';
import Verlinkung from '../../assets/Verlinkung.webp';
import { useStyles } from './style';

export const cardContent = [
  {
    title: <span lang="de">Termin&shy;bestätigung</span>,
    icon: {
      iconName: HandyCheckbox,
    },
    text: 'Du erhältst eine Mail mit der Bestätigung deines Terminwunsches.',
    backgroundColor: theme.color.white,
  },
  {
    title: <span>Link zur Beratung</span>,
    icon: {
      iconName: Verlinkung,
    },
    text: 'Unsere Expert:innen senden dir den Link zu deiner Online-Beratung zu',
    backgroundColor: theme.color.white,
  },
  {
    title: <span>Online-Beratung</span>,
    icon: {
      iconName: TelefonPlus,
    },
    text: 'Wähle dich ganz entspannt per Telefon zu deiner 1:1 Beratung ein.',
    backgroundColor: theme.color.white,
  },
];

interface bundeslandInterface{
  bundesland: string,
  helperText: string,
  error: boolean,
  handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

export function BundeslandDropdown({
  bundesland, helperText, error, handleChange,
}: bundeslandInterface) {
  return (
    <TextField id="bundesland" name="bundesland" helperText={helperText} error={error} variant="filled" style={{ padding: '5px 0px 10px' }} label="Bundesland in dem du als Lehrkraft arbeiten willst/wirst*" value={bundesland} select onChange={handleChange}>
      <MenuItem key="Baden-Württemberg" value="Baden-Württemberg">Baden-Württemberg</MenuItem>
      <MenuItem key="Bayern" value="Bayern">Bayern</MenuItem>
      <MenuItem key="Berlin" value="Berlin">Berlin</MenuItem>
      <MenuItem key="Brandenburg" value="Brandenburg">Brandenburg</MenuItem>
      <MenuItem key="Bremen" value="Bremen">Bremen</MenuItem>
      <MenuItem key="Hamburg" value="Hamburg">Hamburg</MenuItem>
      <MenuItem key="Hessen" value="Hessen">Hessen</MenuItem>
      <MenuItem key="Mecklenburg-Vorpommern" value="Mecklenburg-Vorpommern">Mecklenburg-Vorpommern</MenuItem>
      <MenuItem key="Niedersachsen" value="Niedersachsen">Niedersachsen</MenuItem>
      <MenuItem key="Nordrhein Westfalen" value="Nordrhein Westfalen">Nordrhein Westfalen</MenuItem>
      <MenuItem key="Rheinland-Pfalz" value="Rheinland-Pfalz">Rheinland-Pfalz</MenuItem>
      <MenuItem key="Saarland" value="Saarland">Saarland</MenuItem>
      <MenuItem key="Sachsen" value="Sachsen">Sachsen</MenuItem>
      <MenuItem key="Sachsen-Anhalt" value="Sachsen-Anhalt">Sachsen-Anhalt</MenuItem>
      <MenuItem key="Schleswig-Holstein" value="Schleswig-Holstein">Schleswig-Holstein</MenuItem>
      <MenuItem key="Thüringen" value="Thüringen">Thüringen</MenuItem>
    </TextField>
  );
}

export const getLabels = () => {
  const classes = useStyles();
  return ({
    getLabelBeachte:
  <span className={classes.size}>
    Ja, ich bin einverstanden, dass meine Angaben zum Zweck der Beratung und Information über Produkte der Bayerischen Beamtenkrankenkasse AG, der Bayern-Versicherung Lebensversicherung AG und der Bayerischer Versicherungsverband Versicherungsaktiengesellschaft von den den Expert:innen der Union Krankenversicherung verarbeitet und genutzt werden dürfen und akzeptiere die
    {' '}
    <a href="/datenschutz" target="_blank">Hinweise zum Datenschutz</a>
    .*
  </span>,
    getLabelNewsletter:
  <span className={classes.size}>
    Ich möchte regelmäßig den MeinLehramt-Newsletter mit Informationen rund um das Lehramtsstudium und Neuigkeiten zu Gewinnspielen, Events, etc. erhalten.
  </span>,
    getLabelAdd:
  <span className={classes.size}>
    Ich möchte eine weitere Person zum Termin mitbringen (z.B. Freunde oder
    Eltern).
  </span>,
  });
};
