import { makeVar, ReactiveVar } from '@apollo/client';
import { StartArticleListResponse, StartArticleModel, StartArticleResponseModel } from '../interface/StartArticle';

export const STARTARTICLE = {
  listStartArticles: {
    read(value: StartArticleResponseModel) {
      if (value === undefined) return;
      return startArticleVar({ data: value.data?.map((data: StartArticleModel) => data), isLoading: false });
    },
  },
};

export const startArticleVar: ReactiveVar<StartArticleListResponse> = makeVar<StartArticleListResponse>({
  data: [],
  isLoading: true,
});
