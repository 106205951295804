import { InMemoryCache } from '@apollo/client';
import { ARTICLEPARAGRAPH } from '../components/articleParagraph/api/queryTemplate/ArticleParagraph';
import { PAGE } from '../api/queryTemplate/Page';
import { NEWS } from '../components/news/api/queryTemplate/News';
import { QUOTE } from '../components/quotes/api/queryTemplate/Quote';
import { SLIDERTEXT } from '../components/sliderAndText/api/queryTemplate/SliderText';
import { Navigation } from '../components/navigation/api/model/Navigation';
import { SOCIAL_MEDIA } from '../components/navigation/apiSocialMedia/queryTemplate/SocialMedia';
import { ICONIMAGEBAR } from '../components/iconImageBar/api/queryTemplate/IconImageBar';
import { SOCIALSHARE } from '../components/socialSharing/api/queryTemplate/SocialShare';
import { STARTARTICLE } from '../components/startArticle/api/queryTemplate/StartArticle';
import { ICONLINKBAR } from '../components/iconLinkBar/api/queryTemplate/IconLinkBar';
import { MAP } from '../components/map/api/queryTemplate/Map';
import { INFOBOX } from '../components/infoBox/api/queryTemplate/InfoBox';
import { INFOBOX_WITH_BUTTON } from '../components/infoBoxWithButton/api/queryTemplate/InfoBoxWithButton';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...PAGE,
        ...ICONIMAGEBAR,
        ...ARTICLEPARAGRAPH,
        ...SOCIALSHARE,
        ...NEWS,
        ...QUOTE,
        ...SLIDERTEXT,
        ...Navigation,
        ...SOCIAL_MEDIA,
        ...STARTARTICLE,
        ...ICONLINKBAR,
        ...MAP,
        ...INFOBOX,
        ...INFOBOX_WITH_BUTTON,
      },
    },
  },
});
