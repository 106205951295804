import React from 'react';
import {
  LinkCardContainer, Title, Text, Container,
} from './style';
import ButtonIconBoxComponent from '../../../partials/buttonIconBox/button';

export interface LinkCardProps {
  buttonText: string;
  refLink?: string;
  text: string;
  title: any;
  fontColor: string;
  backgroundColor: string;
  secondaryBtnText?: string;
  secondaryBtnLink?: string;
}

const width = window.innerWidth;

export function LinkCard({
  buttonText,
  text,
  title,
  backgroundColor,
  fontColor,
  refLink,
  secondaryBtnText,
  secondaryBtnLink,
}: LinkCardProps) {
  return (
    <LinkCardContainer
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      height={secondaryBtnText && secondaryBtnLink && width <= 768 ? 'auto' : '90%'}
    >
      <Container>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Container>
      {secondaryBtnText !== undefined && secondaryBtnLink !== undefined && (
        <ButtonIconBoxComponent isDownload text={secondaryBtnText} refLink={secondaryBtnLink} />
      )}
      <ButtonIconBoxComponent text={buttonText} refLink={refLink} />
    </LinkCardContainer>
  );
}

export default LinkCard;
