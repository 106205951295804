import { makeVar, ReactiveVar } from '@apollo/client';
import { QuoteListResponse, QuoteModel, QuotesResponseModel } from '../interface/Quote';

export const QUOTE = {
  listQuotes: {
    read(value: QuotesResponseModel) {
      if (value === undefined) return;
      return quoteVar({ data: value.data?.map((data: QuoteModel) => data), isLoading: false });
    },
  },
};

export const quoteVar: ReactiveVar<QuoteListResponse> = makeVar<QuoteListResponse>({
  data: [],
  isLoading: true,
});
