export const INFOBOX_COMPONENT = `
name
titleContainer{
  ankerId
  isH1
  sectionTitle
}
containerColors{
  containerBackgroundColor
  titleColor
  subtitleColor
  containerShadowColor
  contentLineColor
  componentTitleColor
}
infoContainer{
  mediaContainer{
    mediaFile
    altText
  }
  contentContainer{
    title
    content
  }
}
`;
