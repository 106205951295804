import React from 'react';
import { OutputBlockData as BaseOutputBlockData } from '@editorjs/editorjs';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { Wrapper } from './style';
import { TipTool } from '../../partials/tiptool';
import { theme } from '../../theme';

interface OutputBlockData extends BaseOutputBlockData {
  data: {
    className?: string;
    textAlign?: string;
    text: string;
    caption?: string;
    file?: string;
    level: number;
    items: string[];
    style: string;
  };
}

interface RenderParagraphProps {
  style: {
    [key: string]: string;
  };
  className: string;
}

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node && node.getAttribute('target') === null) node.setAttribute('target', '_blank');
  if (
    !node.hasAttribute('target')
    && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
  ) {
    node.setAttribute('xlink:show', 'new');
  }
});

function recursiveTagCheck(element: any, existingValues: Array<string> | null = null) {
  let tagArr;
  !existingValues ? tagArr = [] as string[] : tagArr = existingValues;
  if (!element) return;
  if (!element.name) return;
  if (element?.type === 'tag') {
    tagArr?.push(element.name);
    recursiveTagCheck(element?.children[0], tagArr);
  }
  if (!element.data) return;
  let innerText = element.data;
  if (tagArr) {
    tagArr.forEach((tag) => {
      switch (tag) {
        case 'u': innerText = `<u>${innerText}</u>`;
          break;
        case 'i': innerText = `<i>${innerText}</i>`;
          break;
        case 'b': innerText = `<b>${innerText}</b>`;
          break;
        default: innerText = 'Unbekannter Type';
      }
    });
  }
  return <span dangerouslySetInnerHTML={{ __html: innerText }} />;
}

function insertTooltip() {
  const options: HTMLReactParserOptions = {
    replace: (props: any) => {
      const {
        attribs,
        children,
        data,
        name,
      } = props;
      if (attribs?.class !== 'cdx-tooltip') {
        if (!data && name === 'a') {
          return (
            <a href={attribs.href} target="_blank" rel="noreferrer">
              {children[0]?.data || ''}
            </a>
          );
        }
        if (!data && children) return recursiveTagCheck(children[0]?.parent);
        return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data, { ALLOWED_TAGS: ['b', 'u', 'i', 'a', 'span', 'br'], ADD_ATTR: ['target', 'style'] }) }} />;
      }
      if (attribs['data-tooltip']) {
        const blockData = attribs['data-tooltip'];
        return (
          <TipTool changedFontColor textInside={children[0]?.data}>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blockData, { ADD_ATTR: ['target', 'style'] }) }} />
          </TipTool>
        );
      }
      return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data, { ADD_ATTR: ['target', 'style'] }) }} />;
    },
  };

  return options;
}

const renderParagraph = (block: OutputBlockData): React.ReactElement => {
  const props: RenderParagraphProps = {
    style: {},
    className: '',
  };

  if (block.data.textAlign) {
    props.style.textAlign = block.data.textAlign;
  }
  if (block.data.className) {
    props.className = block.data.className;
  }

  const options = insertTooltip();

  return (
    <p
      {...props}
      className={classNames('rte-block-paragraph', props.className)}
    >
      {parse(block.data.text, options)}
    </p>
  );
};

const renderDelimiter = () => <div className="rte-block-delimiter" />;

interface RenderHeaderProps {
  style: {
    [key: string]: string;
  };
  className: string;
}

const renderHeader = (block: OutputBlockData) => {
  const props: RenderHeaderProps = {
    style: {},
    className: '',
  };

  if (block.data.textAlign) {
    props.style.textAlign = block.data.textAlign;
  }
  if (block.data.className) {
    props.className = block.data.className;
  }

  const options = insertTooltip();

  switch (block.data.level) {
    case 1:
      return (
        <h1
          {...props}
          className={classNames(
            props.className,
            'rte-block-heading rte-block-heading--h1',
          )}
        >
          {parse(DOMPurify.sanitize(block.data.text), options)}
        </h1>
      );

    case 2:
      return (
        <h2
          {...props}
          className={classNames(
            props.className,
            'rte-block-heading rte-block-heading--h2',
          )}
        >
          {parse(DOMPurify.sanitize(block.data.text), options)}
        </h2>
      );

    case 3:
      return (
        <h3
          {...props}
          className={classNames(
            props.className,
            'rte-block-heading rte-block-heading--h3',
          )}
        >
          {parse(DOMPurify.sanitize(block.data.text), options)}
        </h3>
      );

    case 4:
      return (
        <h4
          {...props}
          className={classNames(
            props.className,
            'rte-block-heading rte-block-heading--h4',
          )}
        >
          {parse(DOMPurify.sanitize(block.data.text), options)}
        </h4>
      );

    case 5:
      return (
        <h5
          {...props}
          className={classNames(
            props.className,
            'rte-block-heading rte-block-heading--h5',
          )}
        >
          {parse(DOMPurify.sanitize(block.data.text), options)}
        </h5>
      );

    case 6:
      return (
        <h6
          {...props}
          className={classNames(
            props.className,
            'rte-block-heading rte-block-heading--h6',
          )}
        >
          {parse(DOMPurify.sanitize(block.data.text), options)}
        </h6>
      );
    default:
      return null;
  }
};

function renderImage(block: OutputBlockData) {
  return <img className="rte-block-image" alt={block.data.caption} src={block.data.file} />;
}

function renderList(block: OutputBlockData) {
  const options = insertTooltip();
  switch (block.data.style) {
    case 'unordered':
      return (
        <ul className="rte-block-list">
          {block.data.items.map((text, i) => (
            <li key={i}>
              {parse(text, options)}
            </li>
          ))}
        </ul>
      );

    case 'ordered':
      return (
        <ol className="rte-block-ordered-list">
          {block.data.items.map((text, i) => (
            <li key={i}>
              {parse(text, options)}
            </li>
          ))}
        </ol>
      );
    default:
      return null;
  }
}

function renderQuote(block: OutputBlockData) {
  return (
    <blockquote className="rte-block-blockquote">
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.data.text) }} />
    </blockquote>
  );
}

const defaultRenderers: Record<string, RichTextBlockRenderer> = {
  delimiter: renderDelimiter,
  header: renderHeader,
  image: renderImage,
  list: renderList,
  paragraph: renderParagraph,
  quote: renderQuote,
};

export interface RichTextBlockRenderer {
  (block: OutputBlockData): React.ReactNode;
}

interface RichTextRendererProps {
  data: OutputBlockData[];
  renderers?: Record<string, RichTextBlockRenderer>;
  listItemIcon?: string;
  isInfoBoxFont?: boolean;
  bulletpointcolor?: string;
}

// eslint-disable-next-line react/function-component-definition
const RichTextRenderer: React.FC<RichTextRendererProps> = (props) => {
  // Combine default renderers with custom renderers
  // eslint-disable-next-line react/destructuring-assignment
  const renderers = { ...defaultRenderers, ...props.renderers };
  const { listItemIcon, isInfoBoxFont, bulletpointcolor } = props;
  return (
    <Wrapper icon={listItemIcon || ''} isCustomFont={isInfoBoxFont || false} bulletpointcolor={bulletpointcolor}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.data.map((block, index) => {
        const renderer = renderers[block.type];
        if (!renderer) {
          return null;
        }
        const node = renderer(block);
        if (React.isValidElement(node)) {
          return React.cloneElement(node, { key: index });
        }
        return null;
      })}
    </Wrapper>
  );
};

export default RichTextRenderer;
