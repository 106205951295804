import styled from 'styled-components';
import { Grid } from '@mui/material';
import { theme } from '../../../theme';

export const Wrapper = styled(Grid)`
  .carousel {
    z-index: 0;
   height: 650px;
    @media (max-width: ${theme.breakPointMobile}){
      height: 850px;
    }
    @media (max-width: 768px){
      height: 750px;
    }
    @media (max-width: 620px){
      height: 650px;
    }
    @media (max-width: 540px){
      height: 610px;
    }
    @media (max-width: 425px){
      height: 575px;
    }
    @media (max-width: 375px){
      height: 480px;
    }
    @media (max-width: 320px){
      height: 420px;
    }
  }
  .carousel img {
    height: 650px;
    object-fit: cover;
    @media (max-width: ${theme.breakPointMobile}){
    height: 850px;
    }
    @media (max-width:768px){
    height: 750px;
    }
    @media (max-width:620px){
    height: 650px;
    }
    @media (max-width: 540px){
    height: 610px;
    }
    @media (max-width:425px){
    height: 575px;
    }
  }

  .carousel .carousel {
    position: absolute;
  }
  .carousel .thumbs-wrapper {
    margin: -13px;
    overflow: hidden;
  }
  .carousel .control-dots .dot {
    opacity: 1;
    box-shadow: none;
    background: none;
    border: 3px solid #fff;
    width: 25px;
    height: 25px;

    @media (max-width: ${theme.breakPointMobile}){
      width: 22px;
      height: 22px;
    }
    @media (max-width: 660px){
      width: 16px;
      height: 16px;
    }
  }
  .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background: #fff;
  }
`;

export const Container = styled(Grid) <{ backgroundcolor?: string }>`
  position: absolute;
  padding: 45px;
  top: 30px;
  left: 80px;
  width: 550px;
  height: 550px;
  background-color: ${(props) => (props.backgroundcolor)};
  @media (max-width: ${theme.breakPointMobile}){
    top: initial !important;;
    left: 10% !important;
    padding: 30px;
    bottom: 60px;
    width: 80% !important;
    height: 80% !important;
  }
  @media (max-width: 768px){
    bottom: 50px;
    height: 80% !important;
  }
  @media (max-width: 610px){
    bottom: 50px;
    height: 90% !important;
  }
  @media (max-width: 428px){
    bottom: 50px;
    height: 80% !important;
  }
  @media (max-width: 375px){
    bottom: 120px;
    height: 70% !important;
  }
  @media (max-width: 320px){
    bottom: 180px;
    height: 65% !important;
  }
  @media (max-width: 280px){
    bottom: 180px;
    height: 63% !important;
  }
`;

export const Title = styled.h1<{ titlecolor?: string }>`
  margin: 5px 0;
  opacity: 1 !important;
  color: ${(props) => (props.titlecolor)};
  font-size: 39px !important;
  text-align: left;
  text-shadow: 1.5px 0 ${(props) => (props.titlecolor)};
  letter-spacing: 2px;
  font-weight:bold;
  @media (max-width: ${theme.breakPointMobile}){
    margin: 0 0;
    font: ${theme.fontMobile.SombraBlack_40} !important;
  }
  @media (max-width: 375px){
    font-size: 19px !important;
  } 
  @media (max-width: 320px){
    font-size: 17px !important;
  }
`;

export const Text = styled.div<{ textcolor?: string }>`
  -webkit-hyphens: auto;
  hyphens: auto;
  text-align: left;
  font-size: 31px !important;
  margin: 31px 0;
  color: ${(props) => (props.textcolor) || theme.color.blau1};
  @media (max-width: ${theme.breakPointMobile}){
    font: ${theme.fontMobile.SombraRegular_NewsMobile} !important;
  } 
  @media (max-width: ${theme.breakPointMobile3}){
    font: ${theme.fontMobile.SombraRegular_40} !important;
  } 
`;

export const ButtonContainer = styled(Grid)` 
 position: absolute;
  bottom: 40px;
  @media (max-width: 425px){
    bottom: 20px;
  }
`;
