import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  BlueButton, DownloadButton, Link, GreenLink,
} from './style';
import sendGAARequest from '../../services/gaaButtonPush';

interface ButtonProps {
  text: string;
  refLink?: string;
  href?: string;
  type?: any;
  isDownload?: boolean;
}

function ButtonIconBoxComponent({
  text,
  refLink,
  href,
  type,
  isDownload = false,
}: ButtonProps) {
  const navigate = useNavigate();
  const routeTo = () => {
    // e.preventDefault();
    if (refLink) navigate(refLink);
    if (href) window.open(href, '_blank');
  };

  if (isDownload) {
    return (
      <GreenLink href={`${refLink}` || '/'}>
        <DownloadButton
          type={type}
          onClick={() => sendGAARequest(refLink)}
        >
          {text || 'Placeholder'}
          <ArrowForwardIcon htmlColor="white" fontSize="medium" aria-hidden="true" data-nosnippet />
        </DownloadButton>
      </GreenLink>
    );
  }
  return (
    <Link href={`${refLink}` || '/'} onClick={routeTo}>
      <BlueButton type={type}>
        {text || 'Placeholder'}
        <ArrowForwardIcon htmlColor="white" fontSize="medium" aria-hidden="true" data-nosnippet />
      </BlueButton>
    </Link>
  );
}

export default ButtonIconBoxComponent;
