import styled from 'styled-components';
import { FormLabel } from '@material-ui/core';
import { theme } from '../../theme';

export const FormTitle = styled(FormLabel)`
  padding-bottom: 15px;
  margin-left: 40px;
  font-size: 16px;
  @media (max-width: 500px) {
    padding-left: 17px;
  }
`;

export const NavBtns = styled(FormLabel)`
  padding-bottom: 15px;
  margin: 15px 15px;
  cursor: pointer;

  @media (max-width: 500px) {
    padding-left: 17px;
  }
`;

export const TextPrev = styled.span`
  font-size: 16px;
  margin-left: 5px;
`;

export const TextNext = styled.span`
  font-size: 16px;
  margin-right: 5px;
`;

export const CalenderTheme = {
  primary: `${theme.color.blau4}`,
  secondary: `${theme.color.beige}`,
  background: `${theme.color.beige}`,
  buttons: {
    disabled: {
      color: '#333',
      background: '#f0f0f0',
    },
    confirm: {
      color: '#fff',
      background: `${theme.color.blau2}`,
      hover: {
        color: '',
        cursor: 'pointer',
        background: `${theme.color.blau4}`,
      },
    },
  },
  feedback: {
    success: {
      color: `${theme.color.blau2}`,
    },
    failed: {
      color: `${theme.color.blau2}`,
    },
  },
};
