export const ARTICLEPARAGRAPH_COMPONENT = `
  name
  titleContainer {
    title
    isH1
    image
    imageAltText
    ankerId
    colorContainer {
        backgroundColor
        titleColor
        textColor
        bulletPointColor
    }
   }
  contentContainer {
    hasVideo
    video
    thumbnail
    content
    hasButton
    isNewTab
    isDownloadButton
    buttonLink
    buttonText
    buttonBackgroundColor
    downloadFile
    buttonOnHoverColor
    buttonTextColor
    listIcon
    }
`;
