export const MAP_COMPONENT = `
  name
  bayern {
    subtitle
    content
  }
  badenWurttemberg {
    subtitle
    content
  }
  sachsen {
    subtitle
    content
  }
  thuringen {
    subtitle
    content
  }
  hessen {
    subtitle
    content
  }
  rheinlandPfalz {
    subtitle
    content
  }
  saarland {
    subtitle
    content
  }
  brandenburg {
    subtitle
    content
  }
  berlin {
    subtitle
    content
  }
  sachsenAnhalt {
    subtitle
    content
  }
  niedersachsen {
    subtitle
    content
  }
  bremen {
    subtitle
    content
  }
  nordrheinWestfahlen {
    subtitle
    content
  }
  mecklenburgVorpommern {
    subtitle
    content
  }
  hamburg {
    subtitle
    content
  }
  schleswigHolstein {
    subtitle
    content
  }
  picture{
    image
    imageAltText
  }
`;
