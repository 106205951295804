import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { theme } from '../../../theme';

export const LinkCardContainer = styled.div<{ backgroundColor: string, fontColor: string, height: string }>`
  color: ${(props) => props.fontColor};
  background-color: ${(props) => props.backgroundColor};
  text-align: start;
  margin-bottom: 10px;
  height: ${(props) => props.height};
  @media(max-width: ${theme.breakPointMobile}){
    margin-bottom: 0px;
    height: auto;
  }
`;

export const Title = styled.div`
  font: ${theme.fontDesktop.SombraBlack_32};
  
  @media(max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraBlack_32};
  }
`;

export const Text = styled.div`
  font: ${theme.fontDesktop.SombraRegular_32};
  @media(max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraRegular_32}
  }
`;

export const Container = styled.div`
  height: 70%;
  @media(max-width: 1600px){
    margin-bottom: 10px;
    height: 70%;
  }
  @media(max-width: 1200px){
    margin-bottom: 30px;
    height: 65%;
  }
  @media(max-width: ${theme.breakPointMobile}){
    margin-bottom: 30px;
    height: 90%;
  }
`;

export const RatioIconBox = styled(Grid)`
  height: 25vw;
  overflow: hidden;
  padding-top: 27%;
  position: relative;
  @media(max-width: ${theme.breakPointMobile}) {
    padding-top: 50%;
  }
`;

export const RatioIconBoxInside = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
`;
