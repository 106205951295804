export const ICONIMAGEBAR_COMPONENT = `
  name
  container {
    leftIconImage
    leftImageAltText
    rightIconImage
    rightImageAltText
  }
  image
  imagePosition
  imageAltText
`;
