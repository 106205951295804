import styled from 'styled-components';
import { theme } from '../../theme';

export const Wrapper = styled.div<{ icon: string, isCustomFont?: boolean, bulletpointcolor?: string }>`
  .rte-block-delimiter {
    line-height: 1.6em;
    width: 100%;
    text-align: center;
    &:before {
      display: inline-block;
      content: "***";
      font-size: 30px;
      line-height: 65px;
      height: 30px;
      letter-spacing: 0.2em;
    }
  }

  u{
    text-decoration: underline;
  }

  .rte-block-paragraph {
    font: ${(props) => (props.isCustomFont && theme.font.SombraRegular_infoBox)};
    margin: 0 0 1.625rem;
  }

  .rte-block-image {
    max-width: 100%;
  }

  .rte-block-heading {
    font-family: inherit;
    box-sizing: border-box;
    margin: 0 0 1.5rem;
    font-weight: 700;
  }

  .rte-block-heading--h1 {
    font-size: 3rem;
    line-height: 1;
  }

  .rte-block-heading--h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
      @media(max-width: ${theme.breakPointMobile}) {
        font-size: 4.60vw;
        line-height: 5.50vw;
      }
  }

  .rte-block-heading--h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .rte-block-heading--h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .rte-block-heading--h5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .rte-block-heading--h6 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  a:active{
    color: ${theme.color.blau3};
  }
  .rte-block-blockquote {
    margin: 0;
    
    & p {
      quotes: "“" "”" "‘" "’";
      box-sizing: border-box;
      margin: 0 0 1.625rem;

      &::before {
        content: open-quote;
      }
      &::after {
        content: close-quote;
      }
    }
  }

  .rte-block-list {
    list-style: inherit;
    box-sizing: inherit;
    padding: 0 0 0 1.7rem;
    font: ${(props) => (props.isCustomFont && theme.font.SombraRegular_infoBox)};
    & ul {
      margin-bottom: 1.75rem;
    }
    & li {
      box-sizing: inherit;
      border: 0 solid currentColor;
      margin: 10px 0;
      padding-left: 8px;
      &::marker {
        content: '\\${(props) => props.icon || '25CF'}';
        color:${(props) => props.bulletpointcolor || theme.color.blau2};
        vertical-align: middle;
      }
    }
    @media(max-width: ${theme.breakPointMobile}) {
      font: ${(props) => (props.isCustomFont && theme.font.SombraRegular_infoBox)};
    } 
  }
  
  .rte-block-ordered-list{
    box-sizing: inherit;
    padding: 0 0 0 2.5rem;
    font: ${(props) => (props.isCustomFont && theme.font.SombraRegular_infoBox)};
    & ol {
      margin-bottom: 1.75rem;
      counter-reset: my-counter;
    }
    & li {
      box-sizing: inherit;
      border: 0 solid currentColor;
      margin: 10px 0;
      padding-left: 8px;
      counter-increment: my-counter;
      &::marker {
        content: counter(my-counter)'.';
        color:${(props) => props.bulletpointcolor || theme.color.blau2};
        vertical-align: auto;
      }
    }
    @media(max-width: ${theme.breakPointMobile}) {
      font: ${(props) => (props.isCustomFont && theme.font.SombraRegular_infoBox)};
    }
  }
  
  ol.rte-block-list{
    padding: 0 0 0 2rem;
  }
`;
