import React from 'react';
import {
  Container, Link,
} from './style';
import StickyProps from '../../interfaces/stickyProps';

export default function StickyComponent({
  href,
  text,
}: StickyProps) {
  return (
    <Container>
      <Link href={href}>{text}</Link>
    </Container>
  );
}
