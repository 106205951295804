import { gql } from '@apollo/client';
import { SOCIAL_MEDIA_COMPONENT } from './queryModel/SocialMediaIconsComponent';

export const GET_SOCIAL_MEDIA = gql`
    {
      listSocialMedias {
            data {
                ${SOCIAL_MEDIA_COMPONENT}
            }
        }
    }
`;
