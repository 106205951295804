import React from 'react';
import ContentLoader from 'react-content-loader';

export function NavBarSkeleton() {
  return (
    <ContentLoader height="80px" width="99.5%" style={{ position: 'relative', top: 0 }}>
      <rect x="10" y="-40" rx="4" ry="4" width="99%" height="200" />
    </ContentLoader>
  );
}

function SkeletonLoader() {
  const windowView = window.innerWidth;
  return (
    <>
      {windowView > 768 && (
      <ContentLoader height="80vh" width="99.5%" style={{ position: 'relative', top: -80 }}>
        <rect x="10" y="-40" rx="4" ry="4" width="99%" height="110" />
        <rect x="10" y="75" rx="2" ry="2" width="99%" height="600" />

        <rect x="10" y="680" rx="2" ry="2" width="49%" height="400" />
        <rect x="50%" y="680" rx="2" ry="2" width="49.65%" height="400" />

        <rect x="10" y="1085" rx="2" ry="2" width="99%" height="300" />

        <rect x="10" y="1390" rx="2" ry="2" width="49%" height="400" />
        <rect x="50%" y="1390" rx="2" ry="2" width="49.65%" height="400" />

        <rect x="10" y="1795" rx="2" ry="2" width="49%" height="400" />
        <rect x="50%" y="1795" rx="2" ry="2" width="49.65%" height="400" />
      </ContentLoader>
      )}
      {windowView > 425 && (
        <ContentLoader height="80vh" width="99.5%" style={{ position: 'relative', top: -80 }}>
          <rect x="6" y="-40" rx="4" ry="4" width="99%" height="110" />
          <rect x="6" y="75" rx="2" ry="2" width="99%" height="600" />

          <rect x="6" y="680" rx="2" ry="2" width="99%" height="600" />

          <rect x="6" y="1285" rx="2" ry="2" width="99%" height="600" />

          <rect x="6" y="1890" rx="2" ry="2" width="99%" height="300" />
          <rect x="6" y="2195" rx="2" ry="2" width="99%" height="300" />
        </ContentLoader>
      )}
      {windowView > 320 && (
        <ContentLoader height="80vh" width="99.5%" style={{ position: 'relative', top: -80 }}>
          <rect x="6" y="-40" rx="4" ry="4" width="99%" height="110" />
          <rect x="6" y="75" rx="2" ry="2" width="99%" height="600" />

          <rect x="6" y="680" rx="2" ry="2" width="99%" height="350" />

          <rect x="6" y="1035" rx="2" ry="2" width="99%" height="350" />
        </ContentLoader>
      )}
      <ContentLoader height="80vh" width="99.5%" style={{ position: 'relative', top: -80 }}>
        <rect x="6" y="-40" rx="4" ry="4" width="99%" height="110" />
        <rect x="6" y="75" rx="2" ry="2" width="99%" height="350" />

        <rect x="6" y="430" rx="2" ry="2" width="99%" height="350" />

        <rect x="6" y="785" rx="2" ry="2" width="99%" height="350" />
      </ContentLoader>
    </>
  );
}

export default SkeletonLoader;
