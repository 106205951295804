import { makeVar, ReactiveVar } from '@apollo/client';
import { Menupunkte, NavbarListResponse, Themenbereich } from '../interface/Navigation';
import { AdvancedPageModel, PageModel } from '../../../../api/interface/Page';

function handleUnterthemen(themenbereich: Themenbereich) {
  return themenbereich.unterthemen.map((unterthema) => {
    if (unterthema.__typename === 'Unterthemen') {
      return ({
        ...unterthema,
        page: unterthema.advancedPages?.length ? unterthema.advancedPages : unterthema.page,
      });
    }
    return unterthema;
  });
}

function handleThemenBereiche(themenbereiche: Themenbereich[]) {
  const filteredThemenbereiche = themenbereiche?.map((themenbereich) => {
    if (themenbereich.__typename === 'Themenbereich') {
      return {
        ...themenbereich,
        unterthemen: themenbereich.advancedUnterthemen?.length ? themenbereich.advancedUnterthemen : themenbereich.unterthemen,
      };
    }

    return {
      ...themenbereich,
    };
  }).filter((themenbereich) => themenbereich.__typename === 'Themenbereich');

  return filteredThemenbereiche?.map((themenbereich) => ({
    ...themenbereich,
    unterthemen: handleUnterthemen(themenbereich),
  }));
}

function handlePages(themenbereich: Themenbereich[]) {
  const filteredPages = themenbereich.map((page) => {
    if (['Page', 'AdvancedPage'].includes(page.__typename)) {
      return {
        name: page.name,
        pageSlug: page.pageSlug,
        __typename: page.__typename,
      } as PageModel|AdvancedPageModel;
    }
    return null;
  }).filter((page): page is PageModel|AdvancedPageModel => page !== null);
  return filteredPages.map((page) => ({
    ...page,
  }));
}

function handleMenuPunkte(menuPunkte: Menupunkte[]) {
  const mappedMenuPunkte = menuPunkte.map((menuPunkt) => ({
    ...menuPunkt,
    themenbereiche: handleThemenBereiche(menuPunkt?.themenbereiche),
    pages: handlePages(menuPunkt?.themenbereiche),
  }));
  return mappedMenuPunkte;
}

export const Navigation = {
  listNavigations: {
    read(value: NavbarListResponse) {
      if (value === undefined) return;
      return navbarVar({
        data: value.data?.map((data) => ({
          name: data.name,
          menupunkte: handleMenuPunkte(data.menupunkte),
        })),
        isLoading: false,
      });
    },
  },
};

export const navbarVar: ReactiveVar<NavbarListResponse> = makeVar<NavbarListResponse>({
  data: [],
  isLoading: true,
});
