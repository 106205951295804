import { SOCIALSHARE_COMPONENT } from '../../components/socialSharing/api/queryModel/SocialShareCompoenent';
import { ICONIMAGEBAR_COMPONENT } from '../../components/iconImageBar/api/queryModel/IconImageBarCompoenent';
import { ARTICLEPARAGRAPH_COMPONENT } from '../../components/articleParagraph/api/queryModel/ArticleParagraphComponent';
import { SLIDERTEXT_COMPONENT } from '../../components/sliderAndText/api/queryModel/SliderTextComponent';
import { QUOTE_COMPONENT } from '../../components/quotes/api/queryModel/QuoteComponent';
import { NEWS_COMPONENT } from '../../components/news/api/queryModel/NewsComponent';
import { ICONLINKBAR_COMPONENT } from '../../components/iconLinkBar/api/query';
import { MAP_COMPONENT } from '../../components/map/api/query';
import { STARTARTICLE_COMPONENT } from '../../components/startArticle/api/query';

export const PAGE_COMPONENT = `
  entryId
  __typename
  name
  pageSlug
  showEyeCatcher
  showNewsletterPopUp
  seo{
    title
    keywords
    metaDescription
    twitterTitle
    twitterDescription
    ogDescription
  }
  pageContent{
    __typename
    ...on News {
      ${NEWS_COMPONENT}
    }
    __typename
    ...on Quote {
       ${QUOTE_COMPONENT}
    }
    __typename
    ...on SliderAndText {
       ${SLIDERTEXT_COMPONENT}
    }
    __typename
    ... on ArticleParagraph {
       ${ARTICLEPARAGRAPH_COMPONENT}
    }
    __typename
    ...on StartArticle {
       ${STARTARTICLE_COMPONENT}
    }
    __typename 
    ... on IconImageBar {
       ${ICONIMAGEBAR_COMPONENT}
    }  
      __typename 
    ... on IconLinkBar {
       ${ICONLINKBAR_COMPONENT}
    }
    __typename 
    ... on SocialShare {
      ${SOCIALSHARE_COMPONENT}
    }
    __typename
    ... on MapContent {
      ${MAP_COMPONENT}
    }
}
`;
