import styled, { keyframes } from 'styled-components';
import { Grid } from '@mui/material';
import {
  menuSelector,
  menuItemSelector, menuButtonSelector,
} from '@szhsin/react-menu/style-utils';
import '@szhsin/react-menu/dist/core.css';
import { theme } from '../../../theme';

export const TopicContainer = styled(Grid)`
    height: 60px;
    @media (max-width: 1150px) {
        display: none !important;
        visibility: hidden;
    }
`;

const menuShow = keyframes`
    from {
        opacity: 0;
    }
`;
const menuHide = keyframes`

    to {
        opacity: 0;
    }
`;
export const PagesTopicContainer = styled(Grid)`
    height: 60px;
    @media (max-width: 1150px) {
        display: none !important;
        visibility: hidden;
    }
`;

export const MenuContainer = styled(Grid)<{ value: number }>`
    width: calc(100% / ${(props) => props.value}) !important;

    &:last-child {
        ${menuSelector.name} {
            width: 15vw;
        }
    }

    ${menuSelector.name} {
        border-radius: 0;
        border-bottom: 5px solid ${theme.color.blau2};
        width: 18vw;
        padding: 0;
        background-color: ${theme.color.blau5};
        font-size: 17px !important;
        user-select: none;
        hyphens: auto;
        -webkit-hyphens: auto;
        -webkit-box-shadow: 0 7px 16px 3px rgba(0, 0, 0, 0.23);
        box-shadow: 0 7px 16px 3px rgba(0, 0, 0, 0.23);
    }

    ${menuSelector.stateOpening} {
        animation: ${menuShow} ease-out;
        animation-duration: 0.25s;
    }

    ${menuSelector.stateClosing} {
        animation: ${menuHide} ease-out forwards;
        animation-duration: 0.25s;
    }

    ${menuItemSelector.name} {
        padding: 3px 0;
        font-weight: normal;
    }

    ${menuItemSelector.hover} {
        position: relative;
        background-color: #bbd1e0;
    }

    ${menuItemSelector.submenu} {
        width: 100% !important;
        color: ${theme.color.blau1};
        font-size: 16px;
        padding: 7px 20px 7px 10px ;
        &::after{
            right: 8px;
        }
    }

    ${menuButtonSelector.name} {
        background-color: ${theme.color.blau3} !important;
        border: none;
        width: 100%;
        padding: 0;
        color: ${theme.color.white};
        text-align: left;
        font-size: 17px;

        .topic {
            color: ${theme.color.white};
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 5px;
            height: 60px;
        }

        .arrow {
            color: ${theme.color.white};
        }

        &:hover {
            background-color: ${theme.color.blau5} !important;
            color: ${theme.color.blau2} !important;

            .topic {
                font-weight: 900;
                color: ${theme.color.blau2} !important;
                width: 100% !important;
            }

            .arrow {
                font-weight: bold;
                color: ${theme.color.blau2};
            }
        }
    }

    &.selected {
        color: ${theme.color.blau2};
    }
`;

export const SubMenuContainer = styled(Grid)`

    ${menuSelector.name} {
        border-bottom: 5px solid ${theme.color.blau2};
        border-radius: 0;
        width: 18vw;
        padding: 0;
        background-color: ${theme.color.blau5};
        font-size: 17px !important;
        user-select: none;
        -webkit-box-shadow: 0 7px 16px 3px rgba(0, 0, 0, 0.23);
        box-shadow: 0 7px 16px 3px rgba(0, 0, 0, 0.23);
    }

    ${menuItemSelector.name}{
        width: 100%;
        padding: 7px 20px 7px 10px ;
        &:hover {
            background-color: #bbd1e0;
        }
    }

    @media (max-width: 1150px) {
        display: none !important;
        visibility: hidden;
    }
`;

export const SubContainer = styled(Grid)`
    padding: 10px 5px;
    @media(max-width: 1150px) {
        display: none;
    }
    &:hover {
        cursor: pointer;
        background-color: #bad0de;
    }
    &:active {
        background-color: ${theme.color.blau4}!important;
    }
    &.subMenu {
        padding-left: 81px !important;
    }
    &.mobile {
        @media(max-width: 1150px) {
            display: flex;
        }
    }
`;

export const GridContainer = styled(Grid)`
    padding: 0px 10px 0 20px;
    height: 100%;

    @media (max-width: 1150px) {
        padding: 6px 8px 6px 8px;
    }
`;

export const Logo = styled(Grid)`
    font: ${theme.font.SombraBlack_H1};
    text-align: start;

    .blue {
        color: ${theme.color.blau2};
    }
    @media (max-width: 1520px) {
        font: ${theme.fontDesktop.SombraBlack_50};
    }
    @media (max-width: 1420px) {
        font: ${theme.fontDesktop.SombraBlack_40};
    }
    @media (max-width: 1150px) {
        font: ${theme.fontMobile.SombraBlack_40};
    }
    @media (max-width: ${theme.breakPointMobile4}) {
        font: ${theme.fontMobile.SombraBlack_50};
    }

    &:hover {
        cursor: pointer;
    }
`;

export const LinkHref = styled.a`
    text-decoration: none;
    color: #FFFFFF;
    font-size: 26px;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }

    &.selected {
        color: ${theme.color.blau2};
        font-weight: bolder;
    }

    @media(max-width: 1320px) {
        font-size: 21px ;
    }
`;

export const LinkHrefTermin = styled.a`
    text-decoration: none;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    border-radius: 25px;
    padding: 7% 10%;
    background-color: ${theme.color.green};
    &:hover {
        cursor: pointer;
    }
    &.selected {
        color: ${theme.color.blau2};
    }
    @media(max-width: 1320px) {
        font-size: 21px ;
    }
`;

export const NoLink = styled(Grid)`
    font-size: 26px;
    display: flex;

    &:hover {
        cursor: pointer;
    }

    &.selected {
        color: ${theme.color.blau2};
    }
    @media(max-width: 1320px) {
        font-size: 21px;
    }
    @media (max-width: 1150px) {
        display: none;
    }
`;

export const LinkMenu = styled(Grid)`
    display: block;
    font-size: 17px !important;
    height: 100%;
    @media (max-width: 1150px) {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:nth-last-child(2){
        background-color: ${theme.color.green}!important;
        border-radius: 20px;
        padding: 1%;
    }

    @media (max-width: 1280px) and (min-width: 1150px) {
        font-size: ${theme.fontSize.smallText};
    }

`;

export const LinkContainer = styled(Grid)`
    height: 100%;
    display: flex;
    align-items: center;

    &.selected {
        color: ${theme.color.blau2};}

`;

export const ArticleLinkHref = styled.a`
    text-decoration: none;
    color: ${theme.color.blau1};
    font-size: 17px !important;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 3px 10px;

    &:hover {
        cursor: pointer;
    }

    &.selected {
        color: ${theme.color.blau2};
    }

    .arrow_item{
        margin-right: 3px;
    }

    @media (max-width: 1280px) {
        font-size: ${theme.fontSize.smallText};
    }
`;

export const LinkName = styled(Grid)`
    margin-left: 5px;
    text-align: left;
    width: 115px;
    font-size: 20px !important;
`;

export const Thema = styled(Grid)<{ backgroundColor?: string}>`
    padding-left: 5px;
    .arrow{
        color: ${theme.color.white} !important;
    }
    &:hover {
            //background-color:  ${(props) => props.backgroundColor};
        background-color:${theme.color.blau5};
        color: ${theme.color.blau2};
        cursor: pointer;
        font-weight: 900;

        .arrow  {
            cursor: pointer;
            font-weight: 900;
            color: ${theme.color.blau2} !important;
        }
    }

`;

export const LinkHrefThemenbereich = styled.div<{value: number}>`
    text-decoration: none;
    color: #fff;
    width: calc(100% / ${(props) => props.value});
    height: 60px;
    font-size: 17px !important;
    display: flex;
    @media(max-width: 1280px) {
        font-size: ${theme.fontSize.smallText};
    }


`;

export const MenuIconWrapper = styled(Grid)`
    @media(min-width: 1150px) {
        display: none;
        visibility: hidden;
    }
    text-align: end;
`;

export const MobileMenu = styled(Grid)`
    justify-content: flex-end;
    justify-items: center;
    flex-direction: row;
    @media (min-width: 1150px) {
        display: none !important;
        visibility: hidden;
    }
`;

export const Link = styled.a`
    display: flex;
    align-content: center;
    text-decoration: none;

    :visited {
        color: white;
    }
`;

export const MenuButton = styled.button`
    text-decoration: none;
    color: #FFFFFF;
    font-size: 20px;
    display: flex;
    &.selected {
        color: ${theme.color.blau2};
    }

    @media(max-width: 1150px) {
        display: none;
    }

    @media(max-width: 1280px) {
        font-size: ${theme.fontSize.smallText};
    }
`;

export const Icon = styled.img`
    width: 27px;
    height: 27px;
    margin: 5px;

    @media (max-width: 320px) {
        min-width: 22px;
        height: 22px;
        margin: 3px;
    }
`;

export const SocialMediaDesktop = styled(Grid)`
    display: flex;
    margin-left: 15px !important;
    justify-content: flex-end;
    flex-direction: row;
    @media (max-width: 1149px) {
        display: none;
    }
`;
