export const constants = {
  RIGHT: 'right',
  LEFT: 'left',
  BUTTON: 'button',
  IMAGE: 'image',
  VIDEO: 'video',
};

export enum STATICPAGES {
  TERMINBUCHUNG = '/terminbuchung'
}

export enum BUNDESLAENDER {
  BAYERN = 'bayern',
  BADEN_WURTTEMBERG = 'badenWurttemberg',
  SACHSEN = 'sachsen',
  THURINGEN = 'thuringen',
  HESSEN = 'hessen',
  RHEINLANDPFALZ = 'rheinlandPfalz',
  SAARLAND = 'saarland',
  BRANDEBURG = 'brandenburg',
  BERLIN = 'berlin',
  SACHSEN_ANHALT = 'sachsenAnhalt',
  NIEDERSACHSEN = 'niedersachsen',
  BREMEN = 'bremen',
  NORDRHEINWESTFAHLEN = 'nordrheinWestfahlen',
  MECKLENBURGVORPOMMERN = 'mecklenburgVorpommern',
  HAMBURG = 'hamburg',
  SCHLESWIGHOLSTEIN = 'schleswigHolstein'
}
