export const ICONLINKBAR_COMPONENT = `
  name
      colorTheme
      container1{
        content
        mediaContainer{
          mediaFile
          altText
          thumbnail
        }
        textButtonContainer{
          title
          subtitle
          subtitleColor
          titleColor
          buttonText
          buttonLink
          buttonTextColor
          buttonBackgroundColor
          hoverColor
          containerBackgroundColor
          hasDownloadButton
          isButtonLinkNewTab
          downloadButtonText
          downloadableFiles
          downloadButtonBackgroundColor
          downloadButtonTextColor
          downloadButtonHoverColor
        }
      }
      container2{
        content
        mediaContainer{
          mediaFile
          altText
          thumbnail
        }
        textButtonContainer{
          title
          subtitle
          subtitleColor
          titleColor
          buttonText
          buttonLink
          buttonTextColor
          buttonBackgroundColor
          hoverColor
          containerBackgroundColor
          hasDownloadButton
          isButtonLinkNewTab
          downloadButtonText
          downloadableFiles
          downloadButtonBackgroundColor
          downloadButtonTextColor
          downloadButtonHoverColor
        }
      }
      container3{
        content
        mediaContainer{
          mediaFile
          altText
          thumbnail
        }
        textButtonContainer{
          title
          subtitle
          subtitleColor
          titleColor
          buttonText
          buttonLink
          buttonTextColor
          buttonBackgroundColor
          hoverColor
          containerBackgroundColor
          hasDownloadButton
          isButtonLinkNewTab
          downloadButtonText
          downloadableFiles
          downloadButtonBackgroundColor
          downloadButtonTextColor
          downloadButtonHoverColor
        }
      }
      container4{
        content
        mediaContainer{
          mediaFile
          altText
          thumbnail
        }
        textButtonContainer{
          title
          subtitle
          subtitleColor
          titleColor
          buttonText
          buttonLink
          buttonTextColor
          buttonBackgroundColor
          hoverColor
          containerBackgroundColor
          hasDownloadButton
          isButtonLinkNewTab
          downloadButtonText
          downloadableFiles
          downloadButtonBackgroundColor
          downloadButtonTextColor
          downloadButtonHoverColor
        }
      }
`;
