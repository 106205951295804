import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { theme } from '../../theme';

export const FooterContainer = styled(Grid)`
  max-width: 1700px;
  margin: 0 auto;
  padding: 25px 12px 25px 12px;
  font: ${theme.font.SombraRegular_text};
  background-color: ${theme.color.blau4};
  color: ${theme.color.white};
`;

export const DesktopView = styled(Grid)`
 display: block !important;
  @media screen and (max-width: ${theme.breakPointMobile}){
    display: none !important;
  }
`;

export const MobileView = styled(Grid)`
  display: none !important;
  @media screen and (max-width: ${theme.breakPointMobile}){
    display: block !important;
  }
`;

export const Arrow = styled(Grid)`
  position: relative;
  top: -4px;
  &::before {
    content: '\\2794';
    color: ${theme.color.white};
  }
  @media screen and (max-width: ${theme.breakPointMobile}){
    top: -2px;
  }
`;

export const TitleContainer = styled(Grid)`
  font: ${theme.fontDesktop.SombraRegular_footer};
  @media screen and (max-width: ${theme.breakPointMobile}){
    font: ${theme.fontMobile.SombraRegular_footer};
    padding-bottom: 15px;
  }
`;

export const BlueText = styled.p`
  margin: 0;
  width: auto !important;
  color: ${theme.color.blau2};
  font: ${theme.fontDesktop.SombraRegular_footer};
  @media screen and (max-width: ${theme.breakPointMobile}){
    font: ${theme.fontMobile.SombraRegular_footer};
  }
`;

export const LinkItem = styled(Grid)`
  margin-left: 10px;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 7px;
  cursor: pointer;
  font: ${theme.fontDesktop.SombraRegular_footer};
  @media screen and (max-width: ${theme.breakPointMobile}){
    font: ${theme.fontMobile.SombraRegular_footer};
  }
`;

export const StyledLink = styled.a`
text-decoration: none;
color: ${theme.color.blau2};
width: 85%;
height: auto;
padding-left: 10px;
`;

export const StyledButton = styled.button`
all: unset;
padding-bottom: 5px;
text-decoration: none;
color: ${theme.color.blau2};
width: 85%;
height: auto;
padding-left: 8px;
`;
