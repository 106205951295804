import React from 'react';
import { Grid } from '@material-ui/core';
import {
  TopicContainer,
} from '../style';
import { NavigationModel } from '../api/interface/Navigation';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import HoverMenu from './hoverMenu';

interface TopicAreaProps {
  isLoadingNavbar: boolean,
  navbarData: NavigationModel,
  selectedTopicArea: string
}

function TopicArea({
  isLoadingNavbar,
  navbarData,
  selectedTopicArea,
}: TopicAreaProps) {
  return (
    <TopicContainer
      container
      alignItems="center"
      direction="row"
    >
      {!isLoadingNavbar && navbarData.menupunkte.map((menupunkte) => (
        <Grid container key={menupunkte.name}>
          {menupunkte.name === selectedTopicArea && menupunkte.themenbereiche?.length
            ? menupunkte.themenbereiche.map((topic, index) => (
              <HoverMenu
                key={topic.name}
                topic={topic}
                length={menupunkte.themenbereiche.length}
                menuIndex={index}
                value={menupunkte.themenbereiche.length}
              />
            ))
            : null}
        </Grid>
      ))}
    </TopicContainer>
  );
}

export default TopicArea;
