import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const LinkGrid = styled(Grid)`
    padding: 20px 15px !important;

    @media (max-width: 768px) {
        padding: 10px 15px !important;
    }
`;

export const IconGrid = styled(Grid)`
    font-weight: 900;
    padding: 20px 15px !important;
`;

export const RatioIconBox = styled(Grid) <{ height: string }>`
    height: ${(props) => props.height};
    overflow: hidden;
    padding-top: 27%;
    position: relative;
    @media (max-width: 500px) {
        padding-top: 32%;
    }
`;

export const RatioIconBoxInside = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
`;
