import { theme } from '../../theme';
import Termin from '../../assets/termin.webp';

export const iconPicCardProps = [
  {
    iconName: 'Fragezeichen',
    iconColor: theme.color.blau1,
    backgroundColor: theme.color.blau4,
  },
  {
    iconName: 'Nachricht',
    iconColor: theme.color.blau4,
    backgroundColor: theme.color.blau2,
  },
  {
    imgSrc: Termin,
  },
];

export const ContentIcons = [
  {
    iconName: 'Herz',
    iconColor: theme.color.blau2,
    backgroundColor: theme.color.beige,
  },
  {
    iconName: 'EuleSeitlichNachricht',
    iconColor: theme.color.blau2,
    backgroundColor: theme.color.beige,
  },
];
