import styled from 'styled-components';
import {
  FormLabel, Grid, makeStyles,
} from '@material-ui/core';
import { theme } from '../../theme';

export const Wrapper = styled(Grid)`
  //background-color: ${theme.color.blau4};
`;

export const CardContainer = styled(Grid)`
  height: 100%;
  background-color: ${theme.color.beige};
  text-align: start;
  padding: 28px 48px;
`;

export const DateContainer = styled(Grid)`
  margin-bottom: 50px !important;
  //background-color: ${theme.color.blau4};
`;

export const QuestionInput = styled(Grid)`
  margin-top: 52px !important;
`;

export const InputContainer = styled(Grid)`
  margin: 0 auto;
  font-family: ${theme.fontDesktop.SombraRegular_26}!important;
  @media(max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraRegular_26}!important;
  }
`;

export const CheckboxContainer = styled(Grid)`
  margin-bottom: 30px;
`;

export const RadioContainer = styled(Grid)`
  margin-bottom: 30px;
`;

export const QuestionContainer = styled(Grid)`
  width: 100%;
  margin-bottom: 25px;
`;

export const TooltipContainer = styled(Grid)`
  width: 100%;
  margin: 5px 0px;
  font: ${theme.fontDesktop.SombraRegular_26};
  @media(max-width: ${theme.breakPointMobile}) {
      margin: 0px 0px;
    font: ${theme.fontMobile.SombraRegular_26} !important;
  }
`;

export const Subtitle = styled.h3<{ color?: string }>`
  text-align: start;
  margin-top: 30px;
  margin-bottom: 20px;
  font: ${theme.fontDesktop.SombraRegular_30}!important;
  color: ${(props) => (props.color || theme.color.blau1)} !important;

  @media(max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraRegular_30}!important;
  }`;

export const Block = styled(Grid)`
margin-bottom: 20px;
  
@media(max-width: ${theme.breakPointMobile}) {
  margin-bottom: 0px;
}
`;

export const IconWrapperEnd = styled(Grid)`
  color: ${theme.color.blau2};
  width: min(15vw, 20vw);
  position: relative;
  left: -12px;
  margin: 50px 0px;
  
  @media (max-width: ${theme.breakPointMobile}) {
    width: 50vw;
    left: -12px;
  }
  @media(max-width: 600px) {
    left: 0px;
  }
`;

export const IconWrapper = styled(Grid)`
  background-color: #FFFFFF;
`;

export const FormHeader = styled(Grid)`
  padding: 15px 0px;
  color: ${theme.color.blau1};
  font: ${theme.fontDesktop.SombraRegular_40};
  @media(max-width: ${theme.breakPointMobile3}) {
    font: ${theme.fontMobile.SombraRegular_40};
    padding: 10px 0px;
  }
`;

export const Section = styled(Grid)`
  padding: 33px 46px 31px;
  background-color: ${() => theme.color.white};
  @media(max-width: 360px) {
    padding: 33px 46px 31px;
  }
`;

export const Section1 = styled(Grid)`
  padding: 40px 40px 40px 60px;
  background-color: ${() => theme.color.blau3};
  color: white;
`;

export const Section2 = styled(Grid)`
  padding: 40px 40px 0px 60px;
  margin-top: 50px;
  background-color: ${() => theme.color.blau1};
  color: white;
`;

export const Section3 = styled(Grid)`
  padding: 40px 60px;
  margin-top: 50px;
  background-color: ${() => theme.color.blau4};
  color: white;
`;

export const IconAsCompWrapper = styled.div`
  width: min(10.833vw, 156px);
  height: 50%;
  position: relative;
  left: 46px;
  margin: -20px 0 20px -40px;

  @media(max-width: ${theme.breakPointMobile}) {
    width: 15.611vw;
    margin: 20px 0 20px -40px;
  }@media(max-width: ${theme.breakPointMobile4}) {
  width: 20.611vw;
  margin: 20px 0 20px -40px;
}
`;

export const Spacer = styled(Grid) <{ margintop?: string }>`
  margin-top: ${(props) => (props.margintop || '30px')};
  @media (max-width: ${theme.breakPointMobile}) {
    margin-top: ${(props) => (props.margintop || '40px')};
  }
  @media (max-width: ${theme.breakPointMobile3}) {
    margin-top: ${(props) => (props.margintop || '30px')};
  }
`;

export const InfoTitle = styled.span`
  font-weight: 700;
  font-size: 1rem;
  color: ${theme.color.blau3};
`;

export const CbLabel = styled.span`
  font-size: 1rem;
`;

export const InfoText = styled.p`
  font-size: 1rem;
`;

export const MissingRequirement = styled.p`
font-size: 1rem;
padding-left: 11px;
color: #F44336;
`;

export const Title = styled(Grid)`
  font: ${theme.fontDesktop.SombraRegular_40};
  color: ${theme.color.blau2};
  margin-bottom: 20px;
`;

export const MainTitle = styled.h1`
margin: 0;
font: ${theme.fontDesktop.SombraBlack_40};
color: ${theme.color.blau2};
@media (max-width: ${theme.breakPointMobile}) {
  font: ${theme.fontMobile.SombraBlack_40};
`;

export const SecondTitle = styled.h2`
margin: 0;
font: ${theme.fontDesktop.SombraBlack_40};
color: ${theme.color.blau2};
@media (max-width: ${theme.breakPointMobile}) {
  font: ${theme.fontMobile.SombraBlack_40};
}
`;

export const Form = styled.form`

`;

export const PflichtFeld = styled.form`
  font-size: 1rem;
  padding-bottom: 30px;
  font-family: ${theme.fontDesktop.SombraRegular_26}!important;
  @media(max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraRegular_26} !important;
  }
`;

export const FormTitle = styled(FormLabel)`
  padding-bottom: 15px;

  @media (max-width: 500px) {
    padding-left: 17px;
  }
`;

export const DesktopView = styled(Grid)`
  display: block;
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const MobileView = styled(Grid)`
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
`;

export const Card = styled(Grid)`
  -webkit-box-shadow: 0 0 12px 5px ${theme.color.blau5};
  box-shadow: 0 0 12px 5px ${theme.color.blau5};
  margin: 20px;
  padding: 10px;
  height: 270px;
  width: 250px !important;
  background-color: ${theme.color.white};
  @media (max-width: 980px) {
    width: 100% !important;
    height: auto;
  }
`;

export const CardTitle = styled.p`
  margin: 40px 0 0 10px;
  word-break: break-word;
  hyphens: auto;
  color: ${theme.color.blau1};
  font-size: 21px !important;
  @media (max-width: 1700px) {
    font-size: 24px !important;
    margin: 40px 0 0 10px;
  } 
  @media (max-width: 1300px) {
    font-size: 20px !important;
    margin: 40px 0 0 10px;
  }
  @media (max-width: 1090px) {
    font-size: 20px !important;
    margin: 40px 0 0 10px;
  } 
  @media (max-width: 980px) {
    font-size: 28px !important;
    margin: 40px 0 0 -50px;
  } 
  @media (max-width: 640px) {
    font-size: 17px !important;
    margin: 40px 0 0 10px;
  }
  @media (max-width: 350px) {
    font-size: 17px !important;
    margin: 40px 0 0 35px;
  }
  
`;

export const CardText = styled(Grid)`
  padding: 20px 15px 10px;
  font-size: 20px !important;
  @media (max-width: 640px) {
    padding: 15px 15px 10px;
    font-size: 17px !important;
  }
`;

export const Icon = styled.img`
  height: 100%;
  padding: 15px 0px 5px 0px;
  width: 80px;
  @media (max-width: 450px) {
    height: auto;
    padding: 5px 0px;
  }
`;

export const useStyles = makeStyles({
  root: {
    color: '#132644',
    '& .MuiTextField-root': {
      width: '100%',
      font: theme.fontDesktop.SombraRegular_26,
    },
    '& .MuiFormLabel-asterisk': {
      color: '#132644',
    },
    '& .MuiFormGroup-root': {
      justifyContent: 'center',
    },
    '& .MuiButtonBase-root': {
      alignSelf: 'flex-start',
    },
    '& .Mui-checked': {
      color: '#132644',
    },
    '& .dlXWNb': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #808080',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#132644',
    },
    '& .MuiFormControl-root': {
      textAlign: 'left',
      margin: 0,
    },
    '& .MuiAlert-standardError': {
      backgroundColor: 'transparent !important',
      padding: '0',
      paddingLeft: '7px',
    },
    '& .SendButton': {
      marginTop: '10px',
    },
    '& .MuiTypography-root': {
      fontFamily: 'Sombra-Regular !important',
      textAlign: 'left',
      margin: 0,
      '@media (max-width: 500px)': {
        fontSize: '16px',

      },
    },
    '& .GenderField': {
      paddingLeft: '12px',
      color: '#132544',
    },
    '& .CheckboxField': {
      paddingLeft: '9px !important',
      paddingTop: '7px',
    },
    '& .addBoxes': {
      paddingTop: '-7px !important',
    },
    '& .MuiTypography-body1': {
      fontFamily: '20px Sombra-Black, sans-serif',
      lineHeight: '1',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Sombra-Regular !important',
    },
    '& .MuiRadio-colorSecondary': {
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Sombra-Regular !important',
      width: '95%',
    },
    '& .MuiFilledInput-multiline': {
      background: 'white',
      padding: '20px 0px 10px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    },
    '& .MuiFilledInput-input': {
      width: '100%',
      background: 'white',
      padding: '33px 12px 10px',
    },
    '& .MuiGrid-root': {
      textAlign: 'left',
      '@media (max-width: 500px)': {
        padding: '5px',
      },
    },
    '& .sc-fjdhpX kGhytb::active': {
      backgroundColor: 'red',
    },
  },
  size: {
    fontSize: 'min(1.806vw, 20px)',
    color: '#132644',
    '@media (max-width: 500px)': {
      font: '3.611vw Sombra-Regular, sans-serif',
    },
  },
  sizeInfos: {
    fontSize: 'min(1.806vw, 20px)',
    color: '#132644',
  },
  phone: {
    '&:hover, &:focus': {
      border: '1px solid #808080',

      '& .flag-dropdown': {
        border: '1px solid black',
      },
    },
  },
  phoneContainer: {
    '&:hover, &:focus-within': {
      '& .flag-dropdown': {
        border: '1px solid #808080',
      },
    },
  },
});
