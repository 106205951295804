import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { Partytown } from '@builder.io/partytown/react';
import App from './App';
import client from './webiny/graphql/client';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <HelmetProvider>
    <Partytown forward={['UC_UI']} />
    <StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </StrictMode>
  </HelmetProvider>,
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
