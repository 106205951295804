import React from 'react';
import { Grid } from '@material-ui/core';
import { footerProps, footerProps2 } from './mock';
import {
  Arrow,
  BlueText,
  DesktopView,
  FooterContainer,
  LinkItem,
  MobileView,
  StyledButton,
  StyledLink,
  TitleContainer,
} from './style';
import useWindowWidthCheck from '../../hooks/useWindowWidthCheck';

interface LinkComponentProps {
  linkTitle: string;
  linkTarget?: string;
}

function handleCookiebanner() {
  // @ts-ignore
  if (!UC_UI) return;
  // @ts-ignore
  UC_UI.showSecondLayer();
}

function LinkComponent({
  linkTitle,
  linkTarget,
}: LinkComponentProps) {
  return (
    <LinkItem container direction="row" justifyContent="flex-start" key={linkTitle}>
      <Arrow />
      {linkTarget
        ? (

          <StyledLink title={linkTitle} href={linkTarget}>
            {linkTitle}
          </StyledLink>
        )
        : (
          <StyledButton
            title={linkTitle}
            onClick={handleCookiebanner}
            type="button"
          >
            {linkTitle}
          </StyledButton>
        )}
    </LinkItem>
  );
}

function FooterComponent() {
  const mobileMaxDisplaySize = 959;
  const isDesktopView = useWindowWidthCheck(mobileMaxDisplaySize);
  return (
    <FooterContainer container>
      {isDesktopView ? (
        <DesktopView container>
          <Grid container item xs={12}>
            <TitleContainer container item xs={6} alignItems="center">
              Mein
              <BlueText>
                Lehramt&nbsp;
              </BlueText>
              - eine Initiative der Versicherungskammer Bayern
            </TitleContainer>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              item
              xs={6}
            >
              <Grid item xs={6}>
                {footerProps.map((link) => {
                  if (link.title === 'Cookie-Einstellungen') {
                    return (
                      <LinkComponent linkTitle={link.title} key={link.title} />
                    );
                  }
                  return (
                    <LinkComponent linkTitle={link.title} linkTarget={link.url} key={link.title} />
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                {footerProps2.map((link) => {
                  if (link.title === 'Cookie-Einstellungen') {
                    return (
                      <LinkComponent linkTitle={link.title} key={link.title} />
                    );
                  }
                  return (
                    <LinkComponent linkTitle={link.title} linkTarget={link.url} key={link.title} />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DesktopView>
      ) : (
        <MobileView container direction="column">
          <Grid item xs={12}>
            <TitleContainer container>
              Mein
              <BlueText>
                Lehramt&nbsp;
              </BlueText>
              - eine Initiative der Versicherungskammer Bayern
            </TitleContainer>
            <Grid item xs={12}>
              {footerProps.concat(footerProps2)
                .map((link) => {
                  if (link.title === 'Cookie-Einstellungen') {
                    return (
                      <LinkComponent linkTitle={link.title} key={link.title} />
                    );
                  }
                  return (
                    <LinkComponent linkTitle={link.title} linkTarget={link.url} key={link.title} />
                  );
                })}
            </Grid>
          </Grid>
        </MobileView>
      )}
    </FooterContainer>
  );
}

export default FooterComponent;
