export const STARTARTICLE_COMPONENT = `
  name
  content {
   title
   titleColor
   text
   textColor
   backgroundColor  
  }
  picture {
    image
    imageAltText
  }
`;
