import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useReactiveVar } from '@apollo/client';
import { pageVar } from '../../api/queryTemplate/Page';

export function Seo() {
  const { data } = useReactiveVar(pageVar);
  const URL = `https://${window.location.host}`;
  return (
    <>
      {data.map((data) => (
        <Helmet
          key={data.name}
          title={data.seo.title || 'MeinLehramt – Tipps und Infos für Lehramtsstudierende, Referandar:innen und Lehrer:innen'}
          meta={[
            { name: 'keywords', content: data.seo.keywords?.join() ? data.seo.keywords?.join() : 'MeinLehramt – Tipps und Infos für Lehramtsstudierende, Referandar:innen und Lehrer:innen' },
            { name: 'description', content: data.seo.metaDescription || 'MeinLehramt unterstützt (angehende) Lehrer:innen beim Einstieg ins Berufsleben und darüber hinaus.' },
            { property: 'og:url', content: `${URL}${data.pageSlug}` },
            { property: 'og:title', content: data.seo.title || 'MeinLehramt – Tipps und Infos für Lehramtsstudierende, Referandar:innen und Lehrer:innen' },
            { property: 'og:description', content: data.seo.ogDescription ? data.seo.ogDescription : data.seo.metaDescription },
            { property: 'twitter:title', content: data.seo.twitterTitle ? data.seo.twitterTitle : 'MeinLehramt – Tipps und Infos für Lehramtsstudierende, Referandar:innen und Lehrer:innen' },
            { property: 'twitter:description', content: data.seo.twitterDescription ? data.seo.twitterDescription : 'MeinLehramt unterstützt (angehende) Lehrer:innen beim Einstieg ins Berufsleben und darüber hinaus.' },
          ]}
          link={[{ rel: 'canonical', href: `${URL}${data.pageSlug}` }]}
        />
      ))}
    </>
  );
}

export default Seo;
