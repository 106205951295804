import styled from 'styled-components';
import { theme } from '../../theme';

export const Container = styled.div`
  position: fixed;
  z-index: 4;
  display: grid;
  right: 0;
  top: 15%;
  background-color: ${theme.color.green};
  writing-mode: vertical-lr;
  text-orientation: mixed;
  align-items: center;
  width: 42px;
`;

export const Link = styled.a`
  color: ${theme.color.blau1} !important;
  text-decoration: none;
  padding: 20px 10px;
  font-weight: bold;
  transform: rotate(180deg);
  font: ${theme.fontDesktop.SombraRegular_26};
  @media (max-width: ${theme.breakPointMobile3}){
    font:${theme.fontMobile.SombraRegular_26};
  }
`;
