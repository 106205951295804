import React, {
  useState, SetStateAction, Dispatch,
} from 'react';
import { Grid } from '@material-ui/core';
// @ts-ignore TODO
import DayTimePicker from '@projekte-oev-berlin/react-day-time-picker/lib_v2/';
import { CalenderTheme, FormTitle } from './style';

interface CalenderComponentProps {
  getDate: (unixTime: string) => void,
  availableDates: number[],
  isSubmitted: boolean,
  setIsSubmitted: Dispatch<SetStateAction<boolean>>,
}

function CalenderComponent({
  getDate,
  availableDates,
  isSubmitted,
  setIsSubmitted,
}: CalenderComponentProps) {
  const [isLoading, updateIsLoading] = useState(false);
  const [isDone, updateIsDone] = useState(false);
  const [dateResponse, setDateResponse] = useState('');

  const timeSlotValidator = (slotTime: Date) => {
    let unixTime = parseInt((slotTime.getTime() / 1000).toFixed(0), 10);
    unixTime -= (unixTime % 1800);
    const isValid = availableDates.includes(unixTime);
    return isValid;
  };

  const updateLoading = () => {
    updateIsLoading(true);
    return isLoading;
  };

  const updateDone = () => {
    updateIsDone(true);
    return isDone;
  };

  const onConfirm = (slotTime: Date) => {
    const unixTime = getUnixTime(slotTime);
    getDate(String(unixTime));
    const resDate = new Date(slotTime).toLocaleDateString('de-DE', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
    const retStr = `${resDate} Uhr.`;
    setDateResponse(retStr);
    updateLoading();
    updateDone();
  };

  const getUnixTime = (slotTime: Date) => {
    let unixTime = parseInt((slotTime.getTime() / 1000).toFixed(0), 10);
    unixTime -= (unixTime % 1800);
    const newTime = new Date(unixTime * 1000);
    if (newTime.getTimezoneOffset() === -60) {
      unixTime -= 3600;
    }
    return unixTime;
  };

  return (
    <Grid>
      <FormTitle required>Datum und Uhrzeit auswählen</FormTitle>
      {/* @ts-ignore TODO */}
      <DayTimePicker
        isSubmitted={isSubmitted}
        setIsSubmitted={setIsSubmitted}
        timeSlotSizeMinutes={30}
        timeSlotValidator={timeSlotValidator}
        availableDates={availableDates}
        onConfirm={onConfirm}
        isDone={isDone}
        confirmText="Diesen Termin vormerken?"
        doneText={dateResponse}
        isLoading={isLoading}
        theme={CalenderTheme}
      />
    </Grid>
  );
}

export default CalenderComponent;
