export const SUPERARTICLEPARAGRAPH_COMPONENT = `
  name
  titleContainer {
    title
    hasHeadline
    headerImageContainer {
        headerImage
        headerImageAltText
    }
    colorContainer {
        backgroundColor
        titleColor
        textColor
        bulletPointColor
    }
    isH1
    image
    imageAltText
    ankerId
   }
  contentContainer {
    hasImage
    paragraphImage
    imageZoomable
    highResImage
    hasVideo
    video
    thumbnail
    content
    hasButton
    isNewTab
    isDownloadButton
    buttonLink
    buttonText
    buttonBackgroundColor
    downloadFile
    buttonOnHoverColor
    buttonTextColor
    listIcon
    paragraphImageAltText
    highResolutionImageAltText
    }
`;
