export const SOCIAL_MEDIA_COMPONENT = `
  name
  socialMediaReference {
    link
    picture {
      image
      imageAltText
    }
  }  
`;
