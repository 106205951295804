import React from 'react';
import { Grid } from '@material-ui/core';
import {
  FacebookIcon, FacebookShareButton,
  WhatsappShareButton, WhatsappIcon,
  LinkedinShareButton, LinkedinIcon,
  EmailShareButton, EmailIcon,
  TwitterShareButton, TwitterIcon,
  FacebookMessengerShareButton, FacebookMessengerIcon,
} from 'react-share';
import ContentBar from '../contentBar';

function SocialSharingComponent() {
  const URL = window.location.href;

  return (
    <Grid container direction="row">
      <ContentBar titleH2="Teile mit deinen Freund:innen">
        <Grid container direction="row">
          <Grid item xs={2}>
            <FacebookShareButton url={URL}>
              <FacebookIcon size={42} round />
            </FacebookShareButton>
          </Grid>
          <Grid item xs={2}>
            <FacebookMessengerShareButton url={URL} appId="">
              <FacebookMessengerIcon size={42} round />
            </FacebookMessengerShareButton>
          </Grid>
          <Grid item xs={2}>
            <WhatsappShareButton url={URL}>
              <WhatsappIcon size={42} round />
            </WhatsappShareButton>
          </Grid>
          <Grid item xs={2}>
            <LinkedinShareButton url={URL}>
              <LinkedinIcon size={42} round />
            </LinkedinShareButton>
          </Grid>
          <Grid item xs={2}>
            <EmailShareButton url={URL}>
              <EmailIcon size={42} round />
            </EmailShareButton>
          </Grid>
          <Grid item xs={2}>
            <TwitterShareButton url={URL}>
              <TwitterIcon size={42} round />
            </TwitterShareButton>
          </Grid>
        </Grid>
      </ContentBar>
    </Grid>
  );
}

export default SocialSharingComponent;
