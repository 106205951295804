import { makeVar, ReactiveVar } from '@apollo/client';
import { IconLinkBarListResponse, IconLinkBarModel } from '../interface/IconLinkBar';

export const ICONLINKBAR = {
  listIconLinkBars: {
    read(value: IconLinkBarListResponse) {
      if (value === undefined) return;
      return iconLinkBarVar({ data: value.data?.map((data: IconLinkBarModel) => data) });
    },
  },
};

export const iconLinkBarVar: ReactiveVar<IconLinkBarListResponse> = makeVar<IconLinkBarListResponse>({
  data: [],
});
