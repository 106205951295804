import React, {
  useCallback, useState,
} from 'react';
import { ClickAwayListener } from '@material-ui/core';
import Headroom from 'react-headroom';
import { Grid } from '@mui/material';
import { NavigationModel } from './api/interface/Navigation';
import TopMenu from './TopMenu';
import { SocialMediaModel } from './apiSocialMedia/interface/SocialMedia';
import TopicArea from './TopicArea';

interface HeaderProps {
  navbarData: NavigationModel
  isLoadingNavbar: boolean
  socialMediaData?: SocialMediaModel
  isLoadingSocialMedia?: boolean
}

function Header({
  navbarData, isLoadingNavbar, socialMediaData, isLoadingSocialMedia,
}: HeaderProps) {
  const initMenuPunkte = useCallback(() => navbarData.menupunkte.reduce((prev, current) => {
    if (current.themenbereiche.length || current.pages.length) {
      return ({
        ...prev,
        [current.name]: false,
      });
    }
    return prev;
  }, {}), [navbarData]);

  const [topicAreaOpen, setTopicAreaOpen] = useState<{ [name: string]: boolean }>(
    initMenuPunkte(),
  );
  const [selectedTopicArea, setSelectedTopicArea] = useState<string>('');
  const handleClickAway = () => {
    setTopicAreaOpen(initMenuPunkte());
    setSelectedTopicArea('');
  };

  const onOpenTopicArea = (event: React.MouseEvent<HTMLDivElement>) => {
    const { id } = event.currentTarget;
    if (topicAreaOpen[id]) {
      setSelectedTopicArea('');
    } else {
      Object.keys(topicAreaOpen).forEach((key) => topicAreaOpen[key] = false);
      setSelectedTopicArea(id);
    }
    setTopicAreaOpen(() => ({
      ...topicAreaOpen,
      [id]: !topicAreaOpen[id],
    }));
  };

  const selectedMenu = navbarData.menupunkte.find((menu) => menu.name === selectedTopicArea);
  return (
    <Headroom style={{ zIndex: '5' }}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid container justifyItems="center">
          {!isLoadingNavbar ? (
            <TopMenu
              isLoadingNavbar={isLoadingNavbar}
              navbarData={navbarData}
              onOpenTopicArea={onOpenTopicArea}
              topicAreaOpen={topicAreaOpen}
              socialMediaData={socialMediaData}
              isLoadingSocialMedia={isLoadingSocialMedia}
              selectedTopicArea={selectedTopicArea}
            />
          ) : null}
          {
            topicAreaOpen[selectedTopicArea] && selectedMenu && !selectedMenu.pages.length
              && (
                <TopicArea
                  isLoadingNavbar={isLoadingNavbar}
                  navbarData={navbarData}
                  selectedTopicArea={selectedTopicArea}
                />
              )
          }
        </Grid>
      </ClickAwayListener>
    </Headroom>
  );
}

export default Header;
