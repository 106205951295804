import { makeVar, ReactiveVar } from '@apollo/client';
import { NewsListResponse, NewsResponseModel } from '../interface/News';

export const NEWS = {
  listNews: {
    read(value: NewsResponseModel) {
      if (value === undefined) return;
      return newsVar({ data: value.data?.map((data) => data) });
    },
  },
};

export const newsVar: ReactiveVar<NewsListResponse> = makeVar<NewsListResponse>({
  data: [],
});
