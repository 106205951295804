import { makeVar, ReactiveVar } from '@apollo/client';
import {
  PageListResponse, Pages, AdvancedPageModel,
} from '../interface/Page';

export const PAGE = {
  listPages: {
    read(value: Pages) {
      if (!value) return;
      return pageVar({ data: value.data?.map((data: AdvancedPageModel) => data), isLoading: false });
    },
  },
  listAdvancedPages: {
    read(value: Pages) {
      if (!value) return;
      const dataArr = value.data?.map((data: AdvancedPageModel) => data);
      return pageVar({ data: [...pageVar().data, ...dataArr], isLoading: false });
    },
  },
};

export const pageVar: ReactiveVar<PageListResponse> = makeVar<PageListResponse>({
  data: [],
  isLoading: true,
});
