import React, { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import HamburgerMenu from 'react-hamburger-menu';
import Sidebar from './Sidebar';
import {
  Wrapper, Navbar,
} from './style';
import { NavigationModel } from '../api/interface/Navigation';

interface TopMobileMenuProps {
  navbarData: NavigationModel,
}

export function MobileNav({
  navbarData,
}: TopMobileMenuProps) {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleSidebarToggle = () => {
    setOpen((current) => !current);
  };

  const content = navbarData?.menupunkte?.map((menupunkt) => (
    (menupunkt.themenbereiche.length > 0 ? {
      id: menupunkt?.name,
      name: menupunkt?.name,
      icon: <i className="fa fa-chrome" />,
      children: [
        {
          content: menupunkt.themenbereiche.map((thema) => ({
            id: thema?.name,
            name: thema?.name,
            children: [
              {
                content: [{
                  id: 'alle Beitraege', name: 'Alle Beiträge', icon: <i className="fa fa-chrome" />, to: thema.pageSlug,
                },
                ...thema.unterthemen.map((unterthema) => (
                  (unterthema !== null ? (unterthema?.__typename === 'Unterthemen' ? {
                    id: unterthema?.name,
                    name: unterthema?.name,
                    icon: <i className="fa fa-chrome" />,
                    children: [
                      {
                        content: unterthema?.page?.map((page) => ({
                          id: page?.name,
                          name: page?.name,
                          to: page?.pageSlug,
                          icon: <i className="fa fa-chrome" />,
                        })),
                      }],
                  } : {
                    id: unterthema?.name,
                    name: unterthema?.name,
                    to: unterthema?.pageSlug,
                    icon: <i className="fa fa-chrome" />,
                  }) : null)
                )),
                ],
              }],
          })),
        }],
    } : menupunkt.pages?.length ? {
      id: menupunkt?.name,
      name: menupunkt?.name,
      icon: <i className="fa fa-chrome" />,
      children: [{
        content: menupunkt.pages.map((thema) => ({
          id: thema?.name,
          name: thema?.name,
          to: thema?.pageSlug,
          icon: <i className="fa fa-chrome" />,
        })),
      }],
    } : {
      id: menupunkt?.name,
      name: menupunkt?.name,
      to: menupunkt?.pageSlug,
      icon: <i className="fa fa-chrome" />,
    })
  ));

  const handleClickAway = () => {
    setOpen(false);
  };

  const options = [
    {
      hideBorder: true,
      content,
    },
  ];

  return (
    <Wrapper>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Sidebar
            open={isOpen}
            onToggle={handleSidebarToggle}
            options={options}
          />
          <Navbar>
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={handleSidebarToggle}
              width={30}
              height={18}
              strokeWidth={4}
              rotate={0}
              color="#fff"
              borderRadius={0}
              animationDuration={0.5}
            />
          </Navbar>
        </div>
      </ClickAwayListener>
    </Wrapper>
  );
}
