import { makeVar, ReactiveVar } from '@apollo/client';
import { SocialShareListResponse, SocialShareModel } from '../interface/SocialShare';

export const SOCIALSHARE = {
  listSocialShares: {
    read(value: SocialShareListResponse) {
      if (value === undefined) return;
      return socialShareVar({ data: value.data?.map((data: SocialShareModel) => data) });
    },
  },
};

export const socialShareVar: ReactiveVar<SocialShareListResponse> = makeVar<SocialShareListResponse>({
  data: [{
    name: '',
    socialMedia: [],
    link: '',
  }],
});
