import { makeVar, ReactiveVar } from '@apollo/client';
import { MapListResponse, MapModel } from '../interface/Map';

export const MAP = {
  listMapContents: {
    read(value: MapListResponse) {
      if (value === undefined) return;
      return mapVar({ data: value.data?.map((data: MapModel) => data) });
    },
  },
};

export const mapVar: ReactiveVar<MapListResponse> = makeVar<MapListResponse>({
  data: [],
});
