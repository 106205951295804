import React, { lazy } from 'react';
import ReactPlayer from 'react-player';
import { useStyles, VideoContainer } from './style';
import Thumbnail from '../../../assets/thumbnail_kuehlschrank.webp';
import { theme } from '../../../theme';
import { QuizdingComponent } from '../../quizding';
import { IconCardProps } from '../../../interfaces/iconCardProps';

const EuleRegenschirm = lazy(() => import('../../../assets/icons/customIcons/euleRegenschirm'));
const Sicherheitsschloss = lazy(() => import('../../../assets/icons/customIcons/sicherheitsschloss'));
const MobilePhone = lazy(() => import('../../../assets/icons/customIcons/mobilePhone'));
const BoxOpen = lazy(() => import('../../../assets/icons/customIcons/boxOpen'));
const Herz = lazy(() => import('../../../assets/icons/customIcons/herz'));
const Euro = lazy(() => import('../../../assets/icons/customIcons/euro'));
const Prozent100 = lazy(() => import('../../../assets/icons/customIcons/prozent100'));
const HerzAusgefuellt = lazy(() => import('../../../assets/icons/customIcons/herzAusgefuellt'));
const Karten = lazy(() => import('../../../assets/icons/customIcons/karten'));
const Close = lazy(() => import('../../../assets/icons/customIcons/close'));
const Check = lazy(() => import('../../../assets/icons/customIcons/check'));
const Stift = lazy(() => import('../../../assets/icons/customIcons/stift'));
const Tasche = lazy(() => import('../../../assets/icons/customIcons/tasche'));
const EuleHammer = lazy(() => import('../../../assets/icons/customIcons/euleHammer'));
const Lehrertasche = lazy(() => import('../../../assets/icons/customIcons/lehrertasche'));
const TafelOhne = lazy(() => import('../../../assets/icons/customIcons/tafelOhne'));
const Rucksack = lazy(() => import('../../../assets/icons/customIcons/rucksack'));
const PultBuecherstapel = lazy(() => import('../../../assets/icons/customIcons/pultBuecherstapel'));
const Regenschirm = lazy(() => import('../../../assets/icons/customIcons/regenschirm'));
const EuleOsterei = lazy(() => import('../../../assets/icons/customIcons/euleOsterei'));
const HerzKreuz = lazy(() => import('../../../assets/icons/customIcons/herzKreuz'));
const HandPflaster = lazy(() => import('../../../assets/icons/customIcons/handPflaster'));
const Flugzeug = lazy(() => import('../../../assets/icons/customIcons/flugzeug'));
const Sparschwein = lazy(() => import('../../../assets/icons/customIcons/sparschwein'));
const Auto = lazy(() => import('../../../assets/icons/customIcons/auto'));
const Haus = lazy(() => import('../../../assets/icons/customIcons/haus'));
const NotSmiling = lazy(() => import('../../../assets/icons/customIcons/notSmiling'));
const Schluessel = lazy(() => import('../../../assets/icons/customIcons/schluessel'));
const BuchTasse = lazy(() => import('../../../assets/icons/customIcons/buchTasse'));
const Lautsprecher = lazy(() => import('../../../assets/icons/customIcons/lautsprecher'));
const Stetoskop = lazy(() => import('../../../assets/icons/customIcons/stetoskop'));
const Schultuete = lazy(() => import('../../../assets/icons/customIcons/schultuete'));
const Peace = lazy(() => import('../../../assets/icons/customIcons/peace'));
const HerzLinie = lazy(() => import('../../../assets/icons/customIcons/herzLinie'));
const CheckListe = lazy(() => import('../../../assets/icons/customIcons/checkListe'));
const Apfel = lazy(() => import('../../../assets/icons/customIcons/apfel'));
const Arzt = lazy(() => import('../../../assets/icons/customIcons/arzt'));
const Buch = lazy(() => import('../../../assets/icons/customIcons/buch'));
const BuecherStapel = lazy(() => import('../../../assets/icons/customIcons/buecherStapel'));
const Eule = lazy(() => import('../../../assets/icons/customIcons/eule'));
const EuleSeitlich = lazy(() => import('../../../assets/icons/customIcons/euleSeitlich'));
const Globus = lazy(() => import('../../../assets/icons/customIcons/globus'));
const Hut = lazy(() => import('../../../assets/icons/customIcons/hut'));
const Kreuz = lazy(() => import('../../../assets/icons/customIcons/kreuz'));
const Nachricht = lazy(() => import('../../../assets/icons/customIcons/nachricht'));
const RegenschirmIconSmall = lazy(() => import('../../../assets/icons/customIcons/regenschirmIconSmall'));
const RegenschirmIcon = lazy(() => import('../../../assets/icons/customIcons/regenschirmIcon'));
const Smiley = lazy(() => import('../../../assets/icons/customIcons/smiley'));
const Zielscheibe = lazy(() => import('../../../assets/icons/customIcons/zielscheibe'));
const Uhr = lazy(() => import('../../../assets/icons/customIcons/uhr'));
const Fragezeichen = lazy(() => import('../../../assets/icons/customIcons/fragezeichen'));
const MIcon = lazy(() => import('../../../assets/icons/customIcons/mIcon'));
const EuleHut = lazy(() => import('../../../assets/icons/customIcons/euleHut'));
const EuleStock = lazy(() => import('../../../assets/icons/customIcons/euleStock'));
const TafelGluehbirne = lazy(() => import('../../../assets/icons/customIcons/tafelGluehbirne'));
const EuleBuchSchirm = lazy(() => import('../../../assets/icons/customIcons/euleBuchSchirm'));
const EuleBuecherstapel = lazy(() => import('../../../assets/icons/customIcons/euleBuecherstapel'));
const EuleNachricht = lazy(() => import('../../../assets/icons/customIcons/euleNachricht'));
const EuleSeitlichNachricht = lazy(() => import('../../../assets/icons/customIcons/euleSeitlichNachricht'));
const EuleSchnee = lazy(() => import('../../../assets/icons/customIcons/euleSchnee'));
const CookieIcon = lazy(() => import('../../../assets/icons/customIcons/cookieIcon'));
const BoxClosed = lazy(() => import('../../../assets/icons/customIcons/boxClosed'));
const Kaffeetasse = lazy(() => import('../../../assets/icons/customIcons/kaffeetasse'));
const KalenderIcon = lazy(() => import('../../../assets/icons/customIcons/kalenderIcon'));
const WorkLifeBalanceIcon = lazy(() => import('../../../assets/icons/customIcons/workLifeBalanceIcon'));
const Zettel = lazy(() => import('../../../assets/icons/customIcons/zettel'));
const Notebook = lazy(() => import('../../../assets/icons/customIcons/notebook'));
const Schultafel = lazy(() => import('../../../assets/icons/customIcons/schultafel'));
const EuleSchraeg = lazy(() => import('../../../assets/icons/customIcons/euleSchraeg'));
const Turnschuh = lazy(() => import('../../../assets/icons/customIcons/turnschuh'));

export function AnwartschaftVideo() {
  const videoURL = `${process.env.REACT_APP_FILEBUCKET}/Consal_Kühlschrank_Lang_1-1_komprimiert.mp4`;
  const width = window.innerWidth;
  const isMobile: boolean = (width <= 768);
  const classes = useStyles();
  if (isMobile) {
    return (
      <VideoContainer
        container
        className={classes.root}
        style={{
          height: '100%',
          aspectRatio: '1',
        }}
      >
        {/* @ts-ignore TODO */}
        <ReactPlayer
          width="100%"
          height="100%"
          controls
          playing
          url={videoURL}
          light={Thumbnail}
        />
      </VideoContainer>
    );
  }
  return (
    <VideoContainer container className={classes.root} style={{ height: '100%' }}>
      {/* @ts-ignore TODO */}
      <ReactPlayer
        width="100%"
        height="100%"
        controls
        playing
        url={videoURL}
        light={Thumbnail}
      />
    </VideoContainer>
  );
}

export function IconCard({
  iconName, iconColor, iconColor2, backgroundColor,
}: IconCardProps) {
  const style = getCardStyles(iconColor, iconColor2, backgroundColor);
  if (iconName === undefined) return <div>Kein Icon gefunden</div>;
  if (iconName === 'Quizding') return <QuizdingComponent {...style.quizding} />;
  if (iconName === 'Video') return <AnwartschaftVideo />;
  const Icon: React.ElementType<{iconColor: string | undefined, iconColor2: string | undefined, backgroundColor: string}> = IconCardContent(iconName);
  return <Icon {...style.default} />;
}

function IconCardContent(iconName: string) {
  switch (iconName) {
    case 'EuleRegenschirm':
      return EuleRegenschirm;
    case 'RegenschirmIcon':
      return RegenschirmIcon;
    case 'RegenschirmIconSmall':
      return RegenschirmIconSmall;
    case 'Karten':
      return Karten;
    case 'Check':
      return Check;
    case 'Tasche':
      return Tasche;
    case 'Stift':
      return Stift;
    case 'Close':
      return Close;
    case 'Lehrertasche':
      return Lehrertasche;
    case 'TafelOhne':
      return TafelOhne;
    case 'Rucksack':
      return Rucksack;
    case 'PultBuecherstapel':
      return PultBuecherstapel;
    case 'Regenschirm':
      return Regenschirm;
    case 'EuleOsterei':
      return EuleOsterei;
    case 'Flugzeug':
      return Flugzeug;
    case 'HerzKreuz':
      return HerzKreuz;
    case 'Sicherheitsschloss':
      return Sicherheitsschloss;
    case 'HandPflaster':
      return HandPflaster;
    case 'Sparschwein':
      return Sparschwein;
    case 'Auto':
      return Auto;
    case 'Haus':
      return Haus;
    case 'NotSmiling':
      return NotSmiling;
    case 'Schluessel':
      return Schluessel;
    case 'BuchTasse':
      return BuchTasse;
    case 'Stetoskop':
      return Stetoskop;
    case 'Turnschuh':
      return Turnschuh;
    case 'EuleSchraeg':
      return EuleSchraeg;
    case 'MobilePhone':
      return MobilePhone;
    case 'Euro':
      return Euro;
    case 'Schultuete':
      return Schultuete;
    case 'Schultafel':
      return Schultafel;
    case 'Lautsprecher':
      return Lautsprecher;
    case 'Notebook':
      return Notebook;
    case 'TafelGluehbirne':
      return TafelGluehbirne;
    case 'Zettel':
      return Zettel;
    case 'Kaffeetasse':
      return Kaffeetasse;
    case 'BoxOpen':
      return BoxOpen;
    case 'BoxClosed':
      return BoxClosed;
    case 'Cookie':
      return CookieIcon;
    case 'EuleSchnee':
      return EuleSchnee;
    case 'Peace':
      return Peace;
    case 'Herzlinie':
      return HerzLinie;
    case 'Checkliste':
      return CheckListe;
    case 'Apfel':
      return Apfel;
    case 'Arzt':
      return Arzt;
    case 'Buch':
      return Buch;
    case 'Buecherstapel':
      return BuecherStapel;
    case 'Globus':
      return Globus;
    case 'Eule':
      return Eule;
    case 'EuleSeitlich':
      return EuleSeitlich;
    case 'EuleHammer':
      return EuleHammer;
    case 'Herz':
      return Herz;
    case 'HerzAusgefuellt':
      return HerzAusgefuellt;
    case 'Hut':
      return Hut;
    case 'Kalender':
      return KalenderIcon;
    case 'Kreuz':
      return Kreuz;
    case 'Nachricht':
      return Nachricht;
    case 'Smiley':
      return Smiley;
    case 'Zielscheibe':
      return Zielscheibe;
    case 'Uhr':
      return Uhr;
    case 'M':
      return MIcon;
    case 'Fragezeichen':
      return Fragezeichen;
    case 'Prozent100':
      return Prozent100;
    case 'EuleHut':
      return EuleHut;
    case 'EuleStock':
      return EuleStock;
    case 'EuleBuchSchirm':
      return EuleBuchSchirm;
    case 'EuleBuecherstapel':
      return EuleBuecherstapel;
    case 'EuleNachricht':
      return EuleNachricht;
    case 'EuleSeitlichNachricht':
      return EuleSeitlichNachricht;
    case 'WorkLifeBalance':
      return WorkLifeBalanceIcon;
    default:
      return Eule;
  }
}

function getCardStyles(iconColor: string | undefined, iconColor2: string | undefined, backgroundColor: string) {
  return {
    default: {
      iconColor, iconColor2: iconColor2 || iconColor, backgroundColor,
    },
    quizding: {
      name: 'Start',
      resultType: 'bubble',
      fontcolorBack: theme.color.blau2,
      fontcolor: theme.color.blau2,
      answersBorderRadius: '10px',
      answersFontColor: theme.color.blau3,
      answersBorder: '1px solid #5684ea',
      answersBackground: theme.color.blau5,
      firstValueFill: theme.color.blau1,
      secondValueFill: theme.color.blau3,
      thirdValueFill: theme.color.blau5,
    },
  };
}

export default IconCard;
