import styled from 'styled-components';
import { theme } from '../../theme';

export const BlueButton = styled.button<{ fontColor?: string, backgroundColor?: string, hovercolor?: string }>`
  padding: 10px 20px;
  font: ${theme.fontDesktop.SombraRegular_26};
  color: ${(props) => (props.fontColor) || theme.color.white};
  background-color: ${(props) => (props.backgroundColor) || theme.color.blau4};
  display: flex;
  justify-content: center;
  align-items: center!important;
  align-content: center!important;
  text-align: left;
  border: none;
  border-radius: 15px;
  opacity: 1;
  transition: 1s;
  
  @media(max-width: ${theme.breakPointMobile5}) {
    font: ${theme.fontMobile.SombraRegular_24};
  }
  
  &:hover {
    background-color: ${(props) => (props.hovercolor) || theme.color.blau5};
    cursor: pointer;
  }
`;

export const GreenButton = styled.button<{ fontColor?: string, backgroundColor?: string, hovercolor?: string }>`
  padding: 10px 20px;
  font: ${theme.fontDesktop.SombraRegular_26};
  color: ${(props) => (props.fontColor) || theme.color.white};
  background-color: ${(props) => (props.backgroundColor) || theme.color.green};
  display: flex;
  justify-content: center;
  align-items: center!important;
  align-content: center!important;
  text-align: left;
  border: none;
  border-radius: 15px;
  opacity: 1;
  transition: 1s;
  
  @media(max-width: ${theme.breakPointMobile5}) {
    font: ${theme.fontMobile.SombraRegular_24};
  }
  
  &:hover {
    background-color: ${(props) => (props.hovercolor) || theme.color.blau5};
    cursor: pointer;
  }
`;

export const NewsButton = styled.button<{ fontcolor?: string, backgroundcolor?: string, hovercolor?: string}>`
  padding: 10px 20px;
  font: ${theme.fontDesktop.SombraRegular_26};
  background-color: ${(props) => (props.backgroundcolor) || theme.color.blau4};
  color: ${(props) => props.fontcolor || theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  border: none;
  border-radius: 15px;
  opacity: 1;
  transition: 1s;
  
  @media(max-width: ${theme.breakPointMobile5}) {
    font: ${theme.fontMobile.SombraRegular_24};
  }
  
  &:hover {
    background-color: ${(props) => (props.hovercolor) || theme.color.blau5};
    cursor: pointer;
  }
`;

export const DownloadButton = styled.button<{ fontColor?: string, backgroundColor?: string, hoverColor?: string}>`
  padding: 10px 20px;
  font: ${theme.fontDesktop.SombraRegular_26};
  background-color: ${(props) => (props.backgroundColor) || theme.color.blau1};
  color: ${(props) => (props.fontColor) || theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  border: none;
  border-radius: 15px;
  opacity: 1;
  transition: 1s;
  
  @media(max-width: ${theme.breakPointMobile5}) {
    font: ${theme.fontMobile.SombraRegular_24};
  }
  
  &:hover {
    background-color: ${(props) => (props.hoverColor) || theme.color.blau5}; 
    cursor: pointer;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
