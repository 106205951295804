import React, {
  useState, useEffect, ChangeEvent, FormEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox, FormControl, FormControlLabel,
  Radio, TextField, RadioGroup, FormGroup, Grid,
} from '@material-ui/core';
import { Alert } from '@mui/lab';
import {
  Wrapper, InputContainer, useStyles, Form, Spacer,
  Block, MainTitle, SecondTitle, Section,
  Section3,
  IconAsCompWrapper,
  MissingRequirement, CardTitle, CardText, Icon, Card,
  FormHeader, TooltipContainer,
} from './style';
import ButtonComponent from '../../partials/button/button';
import CalenderComponent from '../calender';
import {
  cardContent, BundeslandDropdown, getLabels,
} from './data';
import { theme } from '../../theme';
import apiCall from '../../api/apiCalender';
import EuleSeitlichNachricht from '../../assets/icons/eule-seitlich-nachricht.svg';
import { TipTool } from '../../partials/tiptool';
import SocialSharingComponent from '../socialShare';
import { nameValidator, mailValidator } from './utils/validators';

interface CheckboxStates {
  [key: string]: boolean;
}

export default function AppointmentPicker() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [availableDates, setAvailableDates] = useState<number[]>([]);
  const [error, setError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    firstname: '',
    name: '',
    mail: '',
    date: '',
    bundesland: '',
    nachricht: '',
  });
  const [checkBox, setCheckBox] = useState<CheckboxStates>({
    checkboxNewsletter: false,
    checkboxBeachte: false,
    checkboxAdd: false,
  });
  const [errors, setErrors] = useState({
    title: '',
    name: '',
    firstname: '',
    mail: '',
    date: '',
    bundesland: '',
  });

  const isMobile = window.innerWidth < 768;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'mail') return setFormData({ ...formData, [event.target.name]: event.target.value.trim() });
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const checkboxHandler = (event: ChangeEvent) => {
    const target = (event.target as HTMLButtonElement).name;
    const prevState:boolean = checkBox[target];
    setCheckBox({ ...checkBox, [target]: !prevState });
  };

  const getCalenderData = async () => apiCall
    .post('/prod/pull', {
      operation: 'pull',
    })
    .then((response) => {
      setAvailableDates(response.data);
    })
    .catch((error) => error);

  useEffect(() => {
    getCalenderData();
    const interval = setInterval(() => {
      getCalenderData();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const getDate = (unixTime: string) => {
    setFormData({ ...formData, date: unixTime });
  };

  const validate = () => {
    let isValid = true;
    let errors = {
      title: '',
      name: '',
      firstname: '',
      mail: '',
      date: '',
      bundesland: '',
    };
    const nameErrors = nameValidator({ name: formData.name, firstname: formData.firstname });
    errors = { ...errors, ...nameErrors };
    const mailError = mailValidator({ mail: formData.mail });
    errors = { ...errors, ...mailError };

    if (!formData.title.trim()) errors.title = 'Anrede erforderlich';
    if (!formData.date) errors.date = 'Datum und Uhrzeit erforderlich';
    if (!formData.bundesland) errors.bundesland = 'Bitte ein Bundesland auswählen';
    if (Object.values(errors).some((value) => value !== '')) isValid = false;
    setErrors(errors);
    return isValid;
  };

  const bookDate = async () => {
    if (checkBox.checkboxNewsletter) {
      const data = {
        listId: 1722,
        email: formData.mail,
        attributes: {
          Vorname: formData.firstname,
          EWE_MeinLehramt: 'ja',
        },
      };

      fetch(`${process.env.REACT_APP_API_INXMAIL_URL}`, {
        method: 'POST',
        headers: {
          'X-API-KEY': `${process.env.REACT_APP_API_INXMAIL_KEY}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(data),
      });
    }
    const data = {
      title: formData.title,
      first_name: formData.firstname,
      last_name: formData.name,
      mail: formData.mail,
      timestamp: formData.date,
      bring_so: checkBox.checkboxAdd,
      ewe: checkBox.checkboxBeachte,
      ds: false,
      bundesland: formData.bundesland,
      nachricht: formData.nachricht,
    };

    apiCall
      .post('/prod/pull', {
        operation: 'push',
        data,
      })
      .then((response) => {
        if (response?.data?.status === 'Could not book slot') {
          setErrors({ ...errors, date: 'Der ausgewählte Termin ist nicht mehr verfügbar.' });
          return setFormData({ ...formData, date: '' });
        }
        navigate('/termin', { replace: true });
        window.scrollTo(0, 0);
      })
      .catch((error) => error);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!checkBox.checkboxBeachte) return setError(true);
    setError(false);
    const isValid = validate();
    if (!isValid) return;
    getCalenderData();
    setIsSubmitted(true);
    bookDate();
  };

  return (
    <Wrapper container direction="column">
      <Form onSubmit={(event: FormEvent) => handleSubmit(event)} className={classes.root} noValidate autoComplete="off">
        <Block container item xs={12} style={{ padding: '0px' }}>
          <Section container item xs={12} md={6}>
            <Grid item xs={12}>
              <MainTitle>
                Fragen zu deiner Absicherung?
                <br />
                <br />
                Wir beraten dich gern!
                <br />
                <br />
                Natürlich kostenlos und unverbindlich!
              </MainTitle>
            </Grid>
            <IconAsCompWrapper>
              <img
                src={EuleSeitlichNachricht}
                alt="Eule Schirm"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </IconAsCompWrapper>
          </Section>
          <Section item xs={12} md={6} style={{ backgroundColor: theme.color.white }}>
            <SecondTitle>Wähle deinen Wunschtermin</SecondTitle>
            <Spacer margintop="15px" />
            <InputContainer item xs={12}>
              <CalenderComponent availableDates={availableDates} getDate={getDate} isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
              {errors.date && <MissingRequirement>{errors.date}</MissingRequirement>}
            </InputContainer>
          </Section>
        </Block>
        <Section3 item xs={12} md={12} style={{ textAlign: 'center' }}>
          <Grid container justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <FormHeader item xs={12} md={8}>
              Persönliche Daten
            </FormHeader>
          </Grid>
          <Grid container justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <InputContainer item xs={12} md={8}>
              <FormControl error={errors.title !== ''} className="GenderField" component="fieldset" required>
                <RadioGroup aria-label="gender" name="title" value={formData.title} onChange={handleChange} row>
                  <FormControlLabel value="Herr" style={{ color: errors.title ? 'red' : 'black' }} control={<Radio />} label="Herr" />
                  <FormControlLabel value="Frau" style={{ color: errors.title ? 'red' : 'black' }} control={<Radio />} label="Frau" />
                  <FormControlLabel value="Divers" style={{ color: errors.title ? 'red' : 'black' }} control={<Radio />} label="Divers" />
                </RadioGroup>
                {errors.title && (
                <div style={{
                  color: 'red', font: '12px Roboto, Helvetica, Arial, sans-serif', margin: '3px 14px 0px', paddingBottom: '8px',
                }}
                >
                  Bitte wähle eine Anrede aus
                </div>
                )}
              </FormControl>
            </InputContainer>
          </Grid>
          <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <InputContainer item xs={6} md={4} style={{ padding: isMobile ? '5px' : '10px' }}>
              <TextField
                helperText={errors.firstname}
                error={errors.firstname !== ''}
                required
                id="outlined-basic"
                label="Vorname"
                name="firstname"
                variant="filled"
                value={formData.firstname}
                onChange={handleChange}
              />
            </InputContainer>
            <InputContainer item xs={6} md={4} style={{ padding: isMobile ? '5px' : '10px' }}>
              <TextField
                helperText={errors.name}
                error={errors.name !== ''}
                required
                id="outlined-basic"
                label="Nachname"
                name="name"
                variant="filled"
                value={formData.name}
                onChange={handleChange}
              />
            </InputContainer>
          </Grid>
          <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center" style={{ padding: isMobile ? '5px' : '5px 0px' }}>
            <InputContainer item xs={12} md={8}>
              <TextField
                helperText={errors.mail}
                error={errors.mail !== ''}
                required
                id="outlined-basic"
                label="E-Mail-Adresse"
                name="mail"
                variant="filled"
                value={formData.mail}
                onChange={handleChange}
              />
            </InputContainer>
          </Grid>
          <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <InputContainer item xs={12} md={8}>
              <BundeslandDropdown bundesland={formData.bundesland} helperText={errors.bundesland} error={errors.bundesland !== ''} handleChange={handleChange} />
            </InputContainer>
          </Grid>
          <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <InputContainer item xs={12} md={8}>
              <TextField variant="filled" multiline name="nachricht" value={formData.nachricht} onChange={handleChange} id="outlined-basic" label="Damit unsere Expert:innen sich perfekt auf dich vorbereiten können, schreib uns doch kurz, worum es gehen soll /was deine Fragen sind." />
            </InputContainer>
          </Grid>
          <Grid container justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <TooltipContainer item xs={12} md={8}>
              <TipTool textInside="Datenschutz">
                Die Daten, die du hier erfasst, gibst du freiwillig ein. Sie werden an unsere Expert:innen für die Vorbereitung deines Termins übermittelt. Es erfolgt keine Weitergabe an Dritte.
              </TipTool>
            </TooltipContainer>
          </Grid>
          <Grid container justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <InputContainer item xs={12} md={8} style={{ paddingBottom: '40px' }}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    style={{
                      marginTop: '20px',
                      fontSize: '14px',
                    }}
                    className="CheckboxField"
                    labelPlacement="end"
                    control={<Checkbox checked={checkBox.checkboxAdd} onChange={(event) => checkboxHandler(event)} name="checkboxAdd" />}
                    label={getLabels().getLabelAdd}
                  />
                  <FormControlLabel
                    value="beachte"
                    className="CheckboxField"
                    control={<Checkbox checked={checkBox.checkboxBeachte} onChange={(event) => checkboxHandler(event)} name="checkboxBeachte" />}
                    label={getLabels().getLabelBeachte}
                    labelPlacement="end"
                  />
                  {error && (
                  <InputContainer item xs={12}>
                    <Alert severity="error">Sie müssen der Einwilligungserklärung zustimmen.</Alert>
                    <Spacer margintop="20px" />
                  </InputContainer>
                  )}
                  <FormControlLabel
                    value="end"
                    className="CheckboxField"
                    control={<Checkbox checked={checkBox.checkboxNewsletter} onChange={(event) => checkboxHandler(event)} name="checkboxNewsletter" />}
                    label={getLabels().getLabelNewsletter}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </InputContainer>
          </Grid>
          <Grid container justifyContent="center" alignContent="center" alignItems="center" style={{ textAlign: 'center' }}>
            <Grid item>
              <ButtonComponent buttonType="submit" text="Sichere dir deinen Termin" noneArrow needsValidation backgroundColor={theme.color.green} fontColor={theme.color.white} />
              {errors && (
                Object.values(errors)
                  .map((el) => (
                    el !== ''
                      ? (
                        <Alert severity="error" key={el}>{el}</Alert>
                      )
                      : ''
                  ))
              )}
              <Spacer margintop="20px" />
            </Grid>
          </Grid>
        </Section3>
        <Section item xs={12} md={12} style={{ textAlign: 'center' }}>
          <Grid item style={{ textAlign: 'center' }}>
            <SecondTitle style={{
              marginTop: '50px',
              marginBottom: '20px',
            }}
            >
              Was passiert als nächstes?
            </SecondTitle>
          </Grid>
          <Grid container justifyContent="center">
            {cardContent.map((content) => (
              <Card key={content.text}>
                <Grid container direction="row">
                  <Grid item xs={3} md={4}>
                    <Icon src={content.icon.iconName} alt="Eule Icon" />
                  </Grid>
                  <Grid item xs={9} md={8}>
                    <CardTitle>{content.title}</CardTitle>
                  </Grid>
                </Grid>
                <CardText>{content.text}</CardText>
              </Card>
            ))}
          </Grid>
        </Section>
      </Form>
      <SocialSharingComponent />
    </Wrapper>
  );
}
