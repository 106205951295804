interface NameValidatorInterface {
    name: string | null,
    firstname: string | null,
}

interface MailValidatorInterface {
    mail: string | null,
}

export function nameValidator({ name, firstname }: NameValidatorInterface) {
    const localErrorObj = { name: '', firstname: '' };
    if (!name?.trim()) localErrorObj.name = 'Nachname erforderlich';
    if (!firstname?.trim()) localErrorObj.firstname = 'Vorname erforderlich';
    if (!name || !firstname) return localErrorObj;
    if (!/^[A-Za-z]+$/i.test(name)) localErrorObj.name = 'Nur Buchstaben (Nachname)';
    if (!/^[A-Za-z]+$/i.test(firstname)) localErrorObj.firstname = 'Nur Buchstaben (Vorname)';
    return localErrorObj;
}

export function mailValidator({ mail }: MailValidatorInterface) {
    const localErrorObj = { mail: '' };
    if (!mail?.trim()) localErrorObj.mail = 'E-Mail erforderlich';
    if (!mail) return localErrorObj;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(mail)) localErrorObj.mail = 'Bitte gib eine gültige E-Mail-Adresse an';
    return localErrorObj;
}
