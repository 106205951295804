import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  BlueButton, DownloadButton, Link, NewsButton,
} from './style';
import sendGAARequest from '../../services/gaaButtonPush';

interface ButtonProps {
  text: string;
  refLink?: string;
  buttonType?:'submit' | 'reset' | 'button';
  needsValidation?: boolean;
  isDownload?: boolean;
  isNewTab?: boolean;
  isNews?: boolean;
  fontColor?: string;
  backgroundColor?: string;
  fileName?: string;
  onClick?: any;
  noneArrow?: boolean,
  buttonOnHoverColor?: string
}

function ButtonComponent({
  noneArrow, text, refLink, isNewTab, buttonType, fontColor, backgroundColor, fileName, needsValidation, isDownload, isNews, onClick, buttonOnHoverColor,
}: ButtonProps) {
  if (needsValidation) {
    return (
      <Link>
        <BlueButton type={buttonType} backgroundColor={backgroundColor} fontColor={fontColor}>
          {text || 'Placeholder'}
          {noneArrow ?? <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />}
        </BlueButton>
      </Link>
    );
  }
  if (isDownload) {
    return (
      <Link
        href={fileName}
        download
        target={isNewTab ? '_blank' : ''}
        rel="noopener noreferrer"
      >
        <DownloadButton
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          type="button"
          hoverColor={buttonOnHoverColor}
          onClick={() => sendGAARequest(fileName)}
        >
          {text || 'Placeholder'}
          <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />
        </DownloadButton>
      </Link>
    );
  }
  if (isNews) {
    return (
      <Link href={`${refLink}` || '/'} target={isNewTab ? '_blank' : ''}>
        <NewsButton backgroundcolor={backgroundColor} fontcolor={fontColor} hovercolor={buttonOnHoverColor} type="button" onClick={onClick}>
          {text || 'Placeholder'}
          <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />
        </NewsButton>
      </Link>
    );
  }
  return (
    <Link href={`${refLink}` || '/'} target={isNewTab ? '_blank' : ''} rel="noreferrer">
      <BlueButton type={buttonType} backgroundColor={backgroundColor} fontColor={fontColor} hovercolor={buttonOnHoverColor}>
        {text || 'Placeholder'}
        {noneArrow ?? <ArrowForwardIcon htmlColor={fontColor || 'white'} fontSize="medium" aria-hidden="true" data-nosnippet />}
      </BlueButton>
    </Link>
  );
}

export default ButtonComponent;
