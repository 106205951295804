import { makeVar, ReactiveVar } from '@apollo/client';
import { SliderAndTextModel, SliderTextListResponse } from '../interface/SliderAndText';

export const SLIDERTEXT = {
  listSliderAndTexts: {
    read(value: SliderTextListResponse) {
      if (value === undefined) return;
      return sliderAndTextVar({ data: value.data?.map((data: SliderAndTextModel) => data) });
    },
  },
};

export const sliderAndTextVar: ReactiveVar<SliderTextListResponse> = makeVar<SliderTextListResponse>({
  data: [],
});
