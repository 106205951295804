import React from 'react';
import ReactPlayer from 'react-player';
import {
  ContentWrapper,
  TitleH1,
  TitleH2,
  IconAsImgWrapper,
  ContentTextWrapper,
  TitleMediaWrapper,
  IconAsCompWrapper,
  VideoContainer,
  RatioBoxInside,
  useStyles,
} from './style';
import IconCard from '../iconBar/iconCard';
import { IContentBarStyling } from '../../interfaces/contentBarStyling';

export function ContentBar({
  titleH1, titleH2, iconAsImg, image, iconAsComp, children, video, thumbnail,
}: IContentBarStyling) {
  function Video({ video }: any) {
    const classes = useStyles();
    return (
      <VideoContainer container className={classes.root}>
        {/* @ts-ignore  TODO */}
        <ReactPlayer
          controls
          playing
          url={video}
          autoplay={false}
          light={thumbnail}
        />
      </VideoContainer>
    );
  }

  return (
    <ContentWrapper container>
      <TitleMediaWrapper item xs={12} md={6}>
        {titleH1
          && (
            <TitleH1>{titleH1}</TitleH1>
          )}
        {titleH2
          && (
            <TitleH2>{titleH2}</TitleH2>
          )}
        {
          image
          && (
          <RatioBoxInside src={image} alt="pic" />
          )
        }
        {
          iconAsImg
          && (
            <IconAsImgWrapper src={iconAsImg} alt="icon" />
          )
        }
        {
          iconAsComp
          && (
            <IconAsCompWrapper>
              <IconCard
                iconName={iconAsComp.iconName}
                iconColor={iconAsComp.iconColor}
                backgroundColor={iconAsComp.backgroundColor}
              />
            </IconAsCompWrapper>
          )
        }
        {
          video
          && (
            <Video video={video} />
          )
        }
      </TitleMediaWrapper>
      <ContentTextWrapper item xs={12} md={6}>
        {children}
      </ContentTextWrapper>
    </ContentWrapper>
  );
}

export default ContentBar;
