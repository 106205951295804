import { makeVar, ReactiveVar } from '@apollo/client';
import { IconImageBarListResponse, IconImageBarModel } from '../interface/IconImageBar';

export const ICONIMAGEBAR = {
  listIconImageBars: {
    read(value: IconImageBarListResponse) {
      if (value === undefined) return;
      return iconImageBarVar({ data: value.data?.map((data: IconImageBarModel) => data) });
    },
  },
};

export const iconImageBarVar: ReactiveVar<IconImageBarListResponse> = makeVar<IconImageBarListResponse>({
  data: [],
});
