import React from 'react';
import { LinkHref, Logo } from '../style';

function NavBarLogo() {
  return (
    <LinkHref key="home" href="/">
      <Logo>
        Mein
        <span className="blue">Lehramt</span>
      </Logo>
    </LinkHref>
  );
}
export default NavBarLogo;
