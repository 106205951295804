export const SLIDERTEXT_COMPONENT = `
  name
  sliderText {
    title
    content
    buttonText
    hasButton
    backgroundColor
    titleTextColor
    buttonTextColor
    buttonBackgroundColor
    buttonLink
    buttonOnHoverColor
    contentTextColor
    textPosition
  }
  sliderTextPicture: picture {
    image 
    imageAltText 
  }
`;
