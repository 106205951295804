import { makeVar, ReactiveVar } from '@apollo/client';
import { SocialMediaListResponse, SocialMediaListResponseModel, SocialMediaModel } from '../interface/SocialMedia';

export const SOCIAL_MEDIA = {
  listSocialMedias: {
    read(value: SocialMediaListResponseModel) {
      if (value === undefined) return;
      return socialMediaVar({ data: value.data?.map((data: SocialMediaModel) => data), isLoading: false });
    },
  },
};

export const socialMediaVar: ReactiveVar<SocialMediaListResponse> = makeVar<SocialMediaListResponse>({
  data: [],
  isLoading: true,
});
