import React from 'react';
/*
 * import axios from 'axios';
 * import { MyComponent } from '@projekte-oev-berlin/quizding-react/dist';
 */

/* @ts-ignore TODO */
interface Props {
  name?: string;
  resultType?: any;
  fontcolor?: string;
  fontcolorBack?: string;
  firstValueFill?: string;
  secondValueFill?: string;
  thirdValueFill?: string;
  answersBorderRadius?: string;
  answersFontColor?: string;
  answersBorder?: string;
  answersBackground?: string;
}

// const apiURL = `${process.env.REACT_APP_API_URL_Quizding}`;

// const [style, setStyle] = useState();

/*
 * const getstyles = async () => axios
 *   .get(`${apiURL}/getstyles/${quizName}`, {
 *     method: 'GET',
 *   })
 *   .then((response) => {
 *     setStyle(response.data.body.Items[0].styles);
 *     response.data.body.Items[0].styles;
 *   })
 *   .catch((error) => error);
 *
 * {...getstyles()}
 * {...style}
 */

export function QuizdingComponent({
  name,
  resultType,
  firstValueFill,
  secondValueFill,
  thirdValueFill,
  answersBorder,
  answersFontColor,
  answersBorderRadius,
  fontcolor,
  fontcolorBack,
  answersBackground,
}: Props) {
  return (
    <div>hier war mal quizding</div>
  );
}
