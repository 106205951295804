import { gql } from '@apollo/client';
import { NAVIGATION_COMPONENT } from './template/Navigation';

export const GET_NAVIGATION = gql`
    {
        listNavigations {
            data {
                ${NAVIGATION_COMPONENT}
            }
        }
    }
`;
