import { makeVar, ReactiveVar } from '@apollo/client';
import {
  InfoBoxModelWithButton,
  InfoBoxResponseModelWithButton,
  InfoBoxListResponseWithButton,
} from '../interface/InfoBoxWithButton';

export const INFOBOX_WITH_BUTTON = {
  listPlaceholderContentmodel2s: {
    read(value: InfoBoxResponseModelWithButton) {
      if (value === undefined) return;
      return infoBoxVar({
        data: value.data?.map((data: InfoBoxModelWithButton) => data),
      });
    },
  },
};

export const infoBoxVar: ReactiveVar<InfoBoxListResponseWithButton> = makeVar<InfoBoxListResponseWithButton>({
  data: [],
});
