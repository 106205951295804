import React, { useRef, useState } from 'react';
import {
  ControlledMenu, MenuItem, SubMenu, MenuButton,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Grid } from '@material-ui/core';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuItemLink from '../MenuItems';
import { theme } from '../../../../theme';
import { MenuContainer, LinkName } from '../style';
import { Themenbereich } from '../api/interface/Navigation';

interface HoverMenuProps {
  topic: Themenbereich,
  length: number,
  menuIndex: number,
  value: number,
}

function HoverMenu({
  topic,
  length,
  menuIndex,
  value,
}: HoverMenuProps) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const refs = useRef(new Array(length));
  return (
    (
      <MenuContainer value={value}>
        <MenuButton
          ref={refs.current[menuIndex]}
          onMouseEnter={() => setOpen(true)}
        >
          <div className="topic">
            <ArrowForwardIcon htmlColor={theme.color.white} fontSize="small" className="arrow" aria-hidden="true" />
            <LinkName>
              {topic.name}
            </LinkName>
          </div>
          <ControlledMenu
            transition
            state={isOpen ? 'open' : 'closed'}
            anchorRef={refs.current[menuIndex]}
            onMouseLeave={() => setOpen(false)}
            onClose={() => setOpen(false)}
          >
            <MenuItem key={topic.name}>
              <MenuItemLink data={{ name: 'Alle Beiträge', pageSlug: topic?.pageSlug }} />
            </MenuItem>
            {topic.unterthemen.map((item) => (
              <span key={item.name}>
                {item !== null ? (
                  <Grid>
                    {item?.__typename === 'Unterthemen' ? (
                      <SubMenu label={item?.name} offsetX={1} key={item?.name}>
                        {item.page.map((page) => (
                          <MenuItem key={page?.name}>
                            <MenuItemLink data={{ pageSlug: page?.pageSlug, name: page?.name }} />
                          </MenuItem>
                        ))}
                      </SubMenu>
                    ) : (
                      <MenuItem key={item?.name}>
                        <MenuItemLink data={{ pageSlug: item?.pageSlug, name: item?.name }} />
                      </MenuItem>
                    )}
                  </Grid>
                ) : null}
              </span>
            ))}
          </ControlledMenu>
        </MenuButton>
      </MenuContainer>
    )
  );
}

export default HoverMenu;
