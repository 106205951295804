export const NEWS_COMPONENT = `
  name
  newsContainer{
    newsPicture{
      newsImage
      imageAltText
    }
    newsText {
      title
      content
      buttonText
      hasButton
      backgroundColor
      titleTextColor
      buttonTextColor
      buttonBackgroundColor
      buttonLink
      contentTextColor
      buttonOnHoverColor
      }
  }
`;
