import {
  ApolloClient, HttpLink, NormalizedCacheObject,
} from '@apollo/client';
// import Cookies from 'js-cookie';
import { setContext } from '@apollo/client/link/context';
import { cache } from './cache';

const CONTENT_DELIVERY_API_ACCESS_TOKEN = process.env.REACT_APP_API_KEY_WEBINY;
const CONTENT_DELIVERY_API_URL = process.env.REACT_APP_API_URL_WEBINY;

if (!CONTENT_DELIVERY_API_ACCESS_TOKEN || !CONTENT_DELIVERY_API_URL) {
  throw new Error('TOKEN OR API URL ARE MISSING.');
}

const link = new HttpLink({ uri: CONTENT_DELIVERY_API_URL });

/*
 * console.log(CONTENT_DELIVERY_API_URL);
 * console.log(CONTENT_DELIVERY_API_ACCESS_TOKEN);
 */

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: CONTENT_DELIVERY_API_ACCESS_TOKEN || '',
  },
}));

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(link),
  // credentials: 'same-origin',
  cache,
});

export default client;
