import { gql } from '@apollo/client';
import { PAGE_COMPONENT } from './queryModel/PageComponent';
import { ADVANCEDPAGE_COMPONENT } from './queryModel/AdvancedPageComponent';

export const GET_PAGE = gql`
query listPage ($pageSlug: String)
    {
      listPages(where: {pageSlug: $pageSlug}) {
            data {
                ${PAGE_COMPONENT}
            }
        }
        listAdvancedPages(where: {pageSlug: $pageSlug}) {
              data {
                ${ADVANCEDPAGE_COMPONENT}
            }
        }
    }
`;
