import React from 'react';
import { Grid } from '@material-ui/core';
import {
  Block,
  Card,
  CardText,
  CardTitle,
  Icon,
  IconAsCompWrapper,
  MainTitle,
  SecondTitle,
  Section,
  Spacer,
  Wrapper,
} from '../../components/datepicker/style';
import EuleSeitlichNachricht from '../../assets/icons/eule-seitlich-nachricht.svg';
import { theme } from '../../theme';
import { cardContent } from '../../components/datepicker/data';
import SocialSharingComponent from '../../components/socialShare';

function TerminMock() {
  return (
    <>
      <Wrapper container direction="column">
        <Block container item xs={12} style={{ padding: '0px' }}>
          <Section container item xs={12} md={6}>
            <Grid item xs={12}>
              <MainTitle>
                Fragen zu deiner Absicherung?
                <br />
                <br />
                Wir beraten dich gern!
                <br />
                <br />
                Natürlich kostenlos und unverbindlich!
              </MainTitle>
            </Grid>
            <IconAsCompWrapper>
              <img
                src={EuleSeitlichNachricht}
                alt="Eule Schirm"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </IconAsCompWrapper>
          </Section>
          <Section item xs={12} md={6} style={{ backgroundColor: theme.color.white }}>
            <SecondTitle>Wähle deinen Wunschtermin</SecondTitle>
            <Spacer margintop="15px" />
            <iframe
              id="terminIframe"
              title="Terminbuchungs Iframe"
              style={{
                width: '100%',
                height: '800px',
                border: 'none',
              }}
              src="https://www.wunschtermin.vkb.de/livebooking/syncotime/10149"
            />
          </Section>
        </Block>

      </Wrapper>
      <Block container item xs={12} style={{ padding: '0px' }}>
        <Section item xs={12} md={12} style={{ textAlign: 'center', backgroundColor: 'rgb(140,190,250)' }}>
          <Grid item style={{ textAlign: 'center' }}>
            <SecondTitle style={{
              marginTop: '50px',
              marginBottom: '20px',
            }}
            >
              Was passiert als nächstes?
            </SecondTitle>
          </Grid>
          <Grid container justifyContent="center">
            {cardContent.map((content) => (
              <Card key={content.text} style={{ backgroundColor: 'rgb(140,190,250)' }}>
                <Grid container direction="row">
                  <Grid item xs={3} md={4}>
                    <Icon src={content.icon.iconName} alt="Eule Icon" />
                  </Grid>
                  <Grid item xs={9} md={8}>
                    <CardTitle>{content.title}</CardTitle>
                  </Grid>
                </Grid>
                <CardText>{content.text}</CardText>
              </Card>
            ))}
          </Grid>
        </Section>
      </Block>
      <SocialSharingComponent />
    </>
  );
}

export default TerminMock;
