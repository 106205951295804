import React from 'react';
import AppointmentPicker from '../../components/datepicker';
import { IconTextBar } from '../../components/iconBar/IconTextBar';
import { iconPicCardProps } from './mockData';
import { Wrapper } from './style';
import TerminMock from './TerminMock';

function AppointmentPage() {
  return (
    <Wrapper container direction="row">
      <IconTextBar icons={iconPicCardProps} />
      <AppointmentPicker />
    </Wrapper>
  );
}

export function AppointmentPageMock() {
  return (
    <Wrapper container direction="row">
      <IconTextBar icons={iconPicCardProps} />
      <TerminMock />
    </Wrapper>
  );
}

export default AppointmentPage;
