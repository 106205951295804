import { makeVar, ReactiveVar } from '@apollo/client';
import {
  ArticleParagraphListResponse,
  ArticleParagraphModel,
  ArticleParagraphResponseModel,
} from '../interface/ArticleParagraph';

export const ARTICLEPARAGRAPH = {
  listArticleParagraphs: {
    read(value: ArticleParagraphResponseModel) {
      if (value === undefined) return;
      return articleParagraphVar({ data: value.data?.map((data: ArticleParagraphModel) => data) });
    },
  },
};

export const articleParagraphVar: ReactiveVar<ArticleParagraphListResponse> = makeVar<ArticleParagraphListResponse>({
  data: [],
});
