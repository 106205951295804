import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LinkHref, LinkHrefTermin, LinkContainer } from '../style';

interface PageProps {
    data: {
      pageSlug?: string,
      name: string
    }
}

function TopMenuLink({
  data,
}: PageProps) {
  const { name, pageSlug } = data;
  if (name === 'Terminbuchung') {
    return (
      <LinkHrefTermin
        key={name || ''}
        href={pageSlug || ''}
      >
        {name}
      </LinkHrefTermin>
    );
  }
  return (
    <LinkContainer>
      <ArrowForwardIcon fontSize="small" aria-hidden="true" data-nosnippet />
      <LinkHref
        key={name || ''}
        href={pageSlug || ''}
      >
        {name || ''}
      </LinkHref>
    </LinkContainer>
  );
}
export default TopMenuLink;
