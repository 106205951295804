import React from 'react';
import { Grid } from '@material-ui/core';
import IconCard from '../iconCard';
import LinkCard, { LinkCardProps } from '../linkCard';
import {
  IconGrid, LinkGrid, RatioIconBox, RatioIconBoxInside,
} from './style';
import { theme } from '../../../theme';
import { IconCardProps } from '../../../interfaces/iconCardProps';

interface IconTextBarProps {
  icons: (IconCardProps | LinkCardProps | ImageProps)[];
}

// todo kein export aus einer komponente! > interface ordner
export interface ImageProps {
  imgSrc?: string;
  imgOnlySrc?: string;
  objectPosition?: string;
  downloadCenter?: boolean;
}

export function IconTextBar({ icons }: IconTextBarProps) {
  const isIcon = (icon: IconCardProps | LinkCardProps | ImageProps) => (icon as IconCardProps).iconName !== undefined;
  const isImage = (icon: IconCardProps | LinkCardProps | ImageProps) => (icon as ImageProps).imgSrc !== undefined;
  const isOnlyImage = (icon: IconCardProps | LinkCardProps | ImageProps) => (icon as ImageProps).imgOnlySrc !== undefined;
  const isDownloadcenter = (icon: IconCardProps | LinkCardProps | ImageProps) => (icon as ImageProps).downloadCenter !== undefined && (icon as ImageProps).downloadCenter !== false;

  const width = window.innerWidth;

  const renderIcons = icons.map((icon, index) => {
    if (isImage(icon)) {
      return (
        <RatioIconBox
          key={index}
          item
          xs={6}
          md={isDownloadcenter(icon) ? 3 : 6}
          height={isDownloadcenter(icon) && width <= Number((theme.breakPointMobile).replace('px', '')) ? 'auto' : ''}
        >
          <RatioIconBoxInside
            src={(icon as ImageProps).imgSrc}
            alt="iconcard"
            style={{ objectPosition: (icon as ImageProps).objectPosition }}
          />
        </RatioIconBox>
      );
    }
    if (isOnlyImage(icon)) {
      return (
        <RatioIconBox
          key={index}
          item
          xs={6}
          md={3}
          height={isDownloadcenter(icon) && width <= Number((theme.breakPointMobile).replace('px', '')) ? 'auto' : ''}
        >
          <RatioIconBoxInside
            src={(icon as ImageProps).imgOnlySrc}
            alt="iconcard"
            style={{ objectPosition: (icon as ImageProps).objectPosition }}
          />
        </RatioIconBox>
      );
    }
    if (isIcon(icon)) {
      return (
        <IconGrid
          container
          alignItems="center"
          justifyContent="center"
          key={index}
          item
          xs={3}
          md={3}
          style={{ backgroundColor: (icon as IconCardProps).backgroundColor }}
        >
          <IconCard
            {...(icon as IconCardProps)}
          />
        </IconGrid>
      );
    }
    return (
      <LinkGrid
        key={index}
        item
        xs={6}
        md={3}
        style={{ backgroundColor: (icon as LinkCardProps).backgroundColor }}
      >
        <LinkCard
          {...(icon as LinkCardProps)}
        />
      </LinkGrid>
    );
  });

  return (
    <Grid
      container
      style={{
        width: '100%',
      }}
    >
      {renderIcons}
    </Grid>
  );
}

export default IconTextBar;
