export const theme = {
  color: {
    beige: 'rgb(235,235,225)',
    darkBeige: 'rgb(210,210,200)',
    white: 'rgb(255,255,255)',
    blau1: 'rgb(19,38,68)',
    blau2: 'rgb(20,60,220)',
    blau3: 'rgb(86,132,234)',
    blau4: 'rgb(140,190,250)',
    blau5: 'rgb(206,223,234)',
    green: 'rgb(64,255,27)',
    red: 'rgb(255,0,0)',
  },
  newsBackgroundColor: {
    beige: 'rgba(235,235,225, 0.7)',
    darkBeige: 'rgba(210,210,200, 0.7)',
    white: 'rgba(255,255,255, 0.7)',
    blau1: 'rgba(19,38,68, 0.7)',
    blau2: 'rgba(20,60,220, 0.7)',
    blau3: 'rgba(86,132,234, 0.7)',
    blau4: 'rgba(140,190,250, 0.7)',
    blau5: 'rgba(206,223,234, 0.7)',
    green: 'rgba(64,255,27, 0.7)',
    red: 'rgba(255,0,0, 0.7)',
  },
  borderStyle: {
    accordion: '1px solid #EBEBE1',
  },
  fontSize: {
    h1: '60px',
    h2: '40px',
    h3: '32px',
    text: '26px',
    normal: '23px',
    smallText: '20px',
    smallTextXS: '16px',
    smallTextXXS: '15px',
  },
  font: {
    SombraRegular_H1: '60px Sombra-Regular, sans-serif',
    SombraRegular_H2: '40px Sombra-Regular, sans-serif',
    SombraRegular_H3: '32px Sombra-Regular, sans-serif',
    SombraRegular_H4: '30px Sombra-Regular, sans-serif',
    SombraRegular_text: '26px Sombra-Regular, sans-serif',
    SombraRegular_smallText: '24px Sombra-Regular, sans-serif',
    SombraRegular_infoBox: 'clamp(17px, 3vw, 24px) Sombra-Regular, sans-serif',
    SombraBlack_H1: '60px Sombra-Black, sans-serif',
    SombraBlack_H2: '40px Sombra-Black, sans-serif',
    SombraBlack_H3: '32px Sombra-Black, sans-serif',
    SombraBlack_H4: '28px Sombra-Black, sans-serif',
    SombraBlack_text: '26px Sombra-Black, sans-serif',
    SombraBlack_smallText: '20px Sombra-Black, sans-serif',
    SombraBlack_SmartSmallText: '16px Sombra-Black, sans-serif',
  },
  fontDesktop: {
    SombraRegular_60: 'min(4.167vw, 60px) Sombra-Regular, sans-serif',
    SombraRegular_40: 'min(2.778vw, 40px) Sombra-Regular, sans-serif',
    SombraRegular_32: 'min(2.222vw, 32px) Sombra-Regular, sans-serif',
    SombraRegular_30: 'min(2.111vw, 30px) Sombra-Regular, sans-serif',
    SombraRegular_28: 'min(2.001vw, 28px) Sombra-Regular, sans-serif',
    SombraRegular_26: 'min(1.806vw, 26px) Sombra-Regular, sans-serif',
    SombraRegular_24: 'min(1.678vw, 24px) Sombra-Regular, sans-serif',
    SombraRegular_footer: 'min(1.706vw, 26px) Sombra-Regular, sans-serif',
    SombraBlack_90: 'min(5.667vw, 90px) Sombra-Black, sans-serif',
    SombraBlack_80: 'min(5.167vw, 80px) Sombra-Black, sans-serif',
    SombraBlack_72: 'min(4.767vw, 72px) Sombra-Black, sans-serif',
    SombraBlack_70: 'min(4.667vw, 70px) Sombra-Black, sans-serif',
    SombraBlack_60: 'min(4.167vw, 60px) Sombra-Black, sans-serif',
    SombraBlack_50: 'min(3.067vw, 50px) Sombra-Black, sans-serif',
    SombraBlack_40: 'min(2.778vw, 40px) Sombra-Black, sans-serif',
    SombraBlack_36: 'min(2.555vw, 36px) Sombra-Black, sans-serif',
    SombraBlack_32: 'min(2.222vw, 32px) Sombra-Black, sans-serif',
    SombraBlack_28: 'min(2.001vw, 28px) Sombra-Black, sans-serif',
    SombraBlack_26: 'min(1.806vw, 26px) Sombra-Black, sans-serif',
    SombraBlack_20: 'min(1.406vw, 20px) Sombra-Black, sans-serif',
  },
  fontMobile: {
    SombraRegular_60: '8.333vw Sombra-Regular, sans-serif',
    SombraRegular_40: '5.556vw Sombra-Regular, sans-serif',
    SombraRegular_32: '4.444vw Sombra-Regular, sans-serif',
    SombraRegular_30: '4.111vw Sombra-Regular, sans-serif',
    SombraRegular_28: '3.866vw Sombra-Regular, sans-serif',
    SombraRegular_26: '3.611vw Sombra-Regular, sans-serif',
    SombraRegular_24: '2.611vw Sombra-Regular, sans-serif',
    SombraRegular_NewsMobile: '4.344vw Sombra-Regular, sans-serif',
    SombraRegular_footer: '3.311vw Sombra-Regular, sans-serif',
    SombraBlack_60: '8.333vw Sombra-Black, sans-serif',
    SombraBlack_50: '6.556vw Sombra-Black, sans-serif',
    SombraBlack_40: '5.556vw Sombra-Black, sans-serif',
    SombraBlack_32: '4.444vw Sombra-Black, sans-serif',
    SombraBlack_28: '4.112vw Sombra-Black, sans-serif',
    SombraBlack_26: '3.611vw Sombra-Black, sans-serif',
  },
  breakPointFontMax: '1920px',
  breakPointLaptop: '1024px',
  breakPointTablet: '768px',
  breakPointMobile: '959px',
  breakPointMobile2: '1012px',
  breakPointMobile5: '900px',
  breakPointMobile3: '720px',
  breakPointMobile4: '575px',
  breakPointMobileChrome: '500px',
  breakPointMobile6: '425px',

};
