export const INFOBOX_WITH_BUTTON_COMPONENT = `
name
titleContainer{
  ankerId
  isH1
  sectionTitle
}
containerColors{
  containerBackgroundColor
  titleColor
  subtitleColor
  containerShadowColor
  contentLineColor
  componentTitleColor
}
infoContainer{
  mediaContainer{
    mediaFile
    altText
  }
  contentContainer{
    title
    content
  }
  buttonText
  buttonColor
  buttonTextColor
  link
  isButtonLinkNewTab
  isDownloadButton
  mediaFiles
}
`;
