import styled from 'styled-components';
import { theme } from '../../theme';

export const BlueButton = styled.button`
  padding: 10px;
  font: ${theme.fontDesktop.SombraRegular_26};
  background-color: ${theme.color.blau4};
  color: ${theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  border: none;
  border-radius: 15px;
  opacity: 1;
  transition: 1s;
  width: 90%;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraRegular_26};
  }
  &:hover {
    background-color: ${theme.color.blau5};
    cursor: pointer;
  }
`;

export const DownloadButton = styled.button`
  padding: 10px;
  font: ${theme.fontDesktop.SombraRegular_26};
  background-color: ${theme.color.blau1};
  color: ${theme.color.white};
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-align: left;
  border: none;
  border-radius: 15px;
  opacity: 1;
  transition: 1s;
  width: 100%;
  @media (max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraRegular_26};
  }

  &:hover {
    background-color: ${theme.color.blau4};
    cursor: pointer;
  }
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

export const GreenLink = styled.a`
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: ${theme.breakPointMobile}) {
    padding-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Text = styled.div`

`;
