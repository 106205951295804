const Unterthemen = `
        ... on Page {
        __typename
          name
          pageSlug
        },
        ... on Unterthemen {
        __typename
           name
           page{
             name
             pageSlug
            },
            advancedPages{
              __typename
              ... on AdvancedPage{
                name
                pageSlug
              }
               ... on Page{
                name
                pageSlug
              }
            }
       },`;
const AdvancedUnterthemen = `
       ${Unterthemen}
        ... on AdvancedPage{
           __typename
          name
          pageSlug
        },
 `;

const Themenbereich = `
    pageSlug
    unterthemen{
           __typename
      ${Unterthemen}
      }
      advancedUnterthemen {
           __typename
      ${AdvancedUnterthemen}
    },
`;
const Menupunkt = `
      themenbereiche{
      ...on Themenbereich{
      name
      ${Themenbereich}
      }
      ... on AdvancedPage{
          name
          pageSlug
      }
     ... on Page{
      name
      pageSlug
    } 
  }`;
export const NAVIGATION_COMPONENT = `
      name
 menupunkte {
      name
      pageSlug
      ${Menupunkt}
      }
`;
