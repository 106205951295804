import { makeVar, ReactiveVar } from '@apollo/client';
import {
  InfoBoxModel,
  InfoBoxResponseModel,
  InfoBoxListResponse,
} from '../interface/InfoBox';

export const INFOBOX = {
  listPlaceholderContentmodel1s: {
    read(value: InfoBoxResponseModel) {
      if (value === undefined) return;
      return infoBoxVar({
        data: value.data?.map((data: InfoBoxModel) => data),
      });
    },
  },
};

export const infoBoxVar: ReactiveVar<InfoBoxListResponse> = makeVar<InfoBoxListResponse>({
  data: [],
});
