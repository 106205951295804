import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { TextInside, ToolTipInsideStyled } from './style';

interface TipToolProps {
  textInside: any;
  children: any;
  changedFontColor?: boolean;
}

const ToolTipStyle = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(86, 132, 234, 0.9)',
    paddingLeft: '15px',
    paddingTop: '15px',
    paddingBottom: '15px',
    '@media (max-width:600px)': {
      maxWidth: '180px',
      marginTop: '7px',
      marginBottom: '7px',
      paddingLeft: '10px',
      paddingTop: '10px',
    },
  },
}))(Tooltip);

export function TipTool({
  textInside,
  children,
  changedFontColor = false,
}: TipToolProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    // @ts-ignore TODO
    <ToolTipStyle
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={open}
      title={(
        <ToolTipInsideStyled>
          {children}
        </ToolTipInsideStyled>
      )}
      interactive
    >
      <TextInside changedFontColor={changedFontColor} onClick={handleTooltipOpen}>
        {textInside}
      </TextInside>
    </ToolTipStyle>
  );
}
