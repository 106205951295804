import styled from 'styled-components';
import { theme } from '../../theme';

export const TextInside = styled.span<{ changedFontColor?: boolean }>`
  color: ${(props) => ((props.changedFontColor === true && theme.color.blau2) || theme.color.blau3)};
  text-decoration: underline;
`;

export const ToolTipInsideStyled = styled.div`
  font-size: 16px;
  line-height: 1.2;
  
  @media(max-width: 600px) {
    font-size: 12px;
  }
`;
