import React from 'react';
import classNames from 'classnames';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { theme } from '../../../../theme';
import SidebarChildren from './SidebarChildren';
import {
  Wrapper,
} from './style';

function SidebarContent({
  sidebarProps,
  active,
  options,
  handleTabClick,
  handleBackClick,
  ...props
}:any) {
  if (active === '') return <></>;
  return (
    <Wrapper>
      <div {...sidebarProps}>
        <div className="sidebar-main-content">
          {active === undefined ? (
            <></>
          ) : (
          // eslint-disable-next-line
          <div
            className="back-btn"
            onClick={handleBackClick}
          >
            <NavigateBeforeIcon htmlColor={theme.color.white} fontSize="large" />
            <span>{active}</span>
          </div>
          )}
          <div className="sidebar-body">
            {options?.map((data: any) => (
              <ul key={data.content.length}>
                {data.content.map((list: any) => (
                  <div className="listItem" key={list.name}>
                    {list.to && !list.children && !list.disabled ? (
                      <a href={list.to}>
                        {/* eslint-disable-next-line  */}
                        <li
                          className={classNames({ disabled: list.disabled })}
                          onClick={() => handleTabClick(list)}
                          style={{ backgroundColor: list.name === 'Terminbuchung' ? theme.color.green : 'inherit' }}
                        >
                          <span className="flex-align-center">
                            {list.icon && list.icon}
                            <span>
                              {/*      ohne child */}
                              {list.name}
                            </span>
                          </span>
                          <ArrowOutwardIcon htmlColor={theme.color.blau2} fontSize="inherit" style={{ marginRight: '5px' }} />
                        </li>
                        {list.children && list.children(list)}
                      </a>
                    ) : (
                      <>
                        {/* eslint-disable-next-line  */}
                        <li
                          className={classNames({ disabled: list.disabled })}
                          onClick={() => handleTabClick(list)}
                        >
                          <span className="flex-align-center">
                            {list.icon && list.icon}
                            <span>
                              {/* mit child */}
                              {list.name}
                            </span>
                          </span>
                          {list.children && <NavigateNextIcon htmlColor={theme.color.blau2} fontSize="large" />}
                        </li>
                      </>
                    )}
                    {list?.children && (
                    <SidebarChildren
                      {...props}
                      handleBackClick={handleBackClick}
                      handleTabClick={handleTabClick}
                      list={list}
                      active={active}
                    />
                    )}
                  </div>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default SidebarContent;
