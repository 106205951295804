import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../theme';

export const useStyles = makeStyles(() => createStyles({
    root: {
        '& .react-player__shadow': {
            background: 'radial-gradient(rgb(19, 38, 68, 0.7) 50%, rgb(19, 38, 68) 70%)!important',
        },
    },
}));

export const Link = styled.a`
  color: ${theme.color.blau2};
`;

export const Container = styled(Grid)``;

export const TextWrapper = styled.div`
  font: ${theme.fontDesktop.SombraRegular_26};
  color: ${theme.color.blau1};
  text-align: start;

  @media(max-width: ${theme.breakPointMobile}) {
    font: ${theme.fontMobile.SombraRegular_26};
  }
`;

export const Paragraph = styled.div`
  margin-bottom: 26px;

  &.no-margin {
    margin-bottom: 0;
  }
`;

export const VideoContainer = styled(Grid)`
  .playButton{
    box-sizing: border-box;
    width: 74px;
    height: 74px;
    border-color: transparent transparent transparent #202020;
  }
`;
